import { CancelScheduleSend, MoreHoriz } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useEffect, useState } from 'react';

import {
  CancelButton,
  ConfirmButton,
  ConfirmText,
  ConfirmTitle,
} from '@/components/styled/ConfimModal';
import { ContextMenu } from '@/components/styled/Menu';
import {
  actionConfirmation,
  ConfirmModal,
  EActionConfirm,
} from '@/constants/actionConfirm';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import { unbanChat } from '@/store/thunks';
import { BanReport } from '@/types';

import WarningSign from '../../../assets/pictures/PopupBadge.svg';
import { Modal } from '../Modal';
import { WarningBox } from '../Modal/styled';

interface ContextMenuReportProps {
  params: GridRenderCellParams<BanReport>;
  onUnban?: (id: string) => void;
}

export const ContextMenuBan: FC<ContextMenuReportProps> = ({
  params,
  onUnban,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [ban, setBan] = useState<BanReport | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<ConfirmModal>(
    actionConfirmation[EActionConfirm.UNBAN]
  );

  useEffect(() => {
    setBan(params.row);
  }, [params.row]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const openConfirmModal = useCallback((action: EActionConfirm) => {
    setModal(actionConfirmation[action]);
    setIsOpenModal(true);
  }, []);

  const unbanUser = useCallback(() => {
    dispatch(
      unbanChat({
        user_id: params.row.user?.id || '',
        target: params.row.target,
        targetId: params.row.targetId || '',
      })
    )
      .unwrap()
      .then(() => {
        onUnban && onUnban(params.row.id);
        handleClose();
      })
      .catch((err) => {
        ToastService.error(err);
      });
  }, [dispatch, params.row, onUnban]);

  const modalClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const confirmActionHandle = useCallback(
    (action: EActionConfirm) => {
      switch (action) {
        case EActionConfirm.UNBAN: {
          unbanUser();
          break;
        }
        default:
          break;
      }
      modalClose();
    },
    [unbanUser, modalClose]
  );

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <MoreHoriz />
      </IconButton>
      {params.row.target ? (
        <ContextMenu
          id={`context-menu-${params.row.id}`}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => openConfirmModal(EActionConfirm.UNBAN)}>
            <ListItemIcon>
              <CancelScheduleSend fontSize="small" />
            </ListItemIcon>
            <ListItemText>Unban</ListItemText>
          </MenuItem>
        </ContextMenu>
      ) : null}
      {ban?.user || modal.action === EActionConfirm.DELETE_REPORT ? (
        <Modal isOpen={isOpenModal} hasCloseIcon onClose={modalClose}>
          <WarningBox>
            <img src={WarningSign} />
            <ConfirmTitle>{modal.title}</ConfirmTitle>
            <ConfirmText>{modal.text(ban?.user?.username || '')}</ConfirmText>
            <Box display="flex" justifyContent="space-between" width="100%">
              <CancelButton
                className="cancel"
                variant="contained"
                onClick={() => modalClose()}
              >
                Cancel
              </CancelButton>
              <ConfirmButton
                variant="contained"
                onClick={() => confirmActionHandle(modal.action)}
                color={
                  modal.action === EActionConfirm.UNBAN ? 'primary' : 'error'
                }
              >
                {modal.activeButton}
              </ConfirmButton>
            </Box>
          </WarningBox>
        </Modal>
      ) : null}
    </Box>
  );
};
