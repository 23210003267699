import { CheckCircle, Search } from '@mui/icons-material';
import { Box, capitalize, InputAdornment, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextMenuReport } from '@/components/common/ContextMenuReport';
import { CustomFilterIcon } from '@/components/common/GridFilterCustom';
import { GridFilterCustom } from '@/components/common/GridFilterCustom/GridFilterCustom';
import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';
import { StatusChip } from '@/components/styled/Chip';
import { ROUTES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  getReportFilteredCount,
  getReportLoading,
  getReportsList,
  getTotalCount,
} from '@/store/selectors/reports';
import { getFilteredReports, getMoreReports, getReports } from '@/store/thunks';
import { theme } from '@/theme';
import { ReportStatusColor } from '@/theme/theming/statusesTheme';
import {
  ChatType,
  EChatType,
  EReason,
  EReportObject,
  EReportObjectSend,
  EReportStatus,
  EReportTabType,
  ESortDirection,
  Report as TReport,
  ReportReason,
} from '@/types';

import NoReport from '../../../../assets/pictures/ReportOff.png';
import NoSearch from '../../../../assets/pictures/Search.svg';
import { SearchInput, TableContent } from '../../styled/TableWrapper';
import { sortedFields, TSortable } from './constants/sortModels';

// eslint-disable-next-line react/no-multi-comp
const NoFilterResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
      height="100%"
    >
      <img src={NoSearch} />
      <EmptyReport>No report found</EmptyReport>
    </Box>
  );
};

const rowsMapper = (reports: Array<TReport>) =>
  reports.map((report) => ({
    createdAt: report.createdAt,
    reportObject: report.context?.messageId
      ? EReportObject.MESSAGE
      : EReportObject.USER,
    target: report.target,
    id: report.id,
    user: report.user || null,
    sender: report.sender || null,
    reason: report.reason,
    message: report.context?.messageId
      ? report.context?.messageText || 'Picture'
      : '',
    status: report.status,
    archivedAt: report.archivedAt,
  }));

interface ReportTableProps {
  type: EReportTabType;
  shouldClearReports: boolean;
}

// eslint-disable-next-line react/no-multi-comp
export const ReportTable: FC<ReportTableProps> = ({
  type,
  shouldClearReports,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allReports = useAppSelector(getReportsList);
  const totalCount = useAppSelector(getTotalCount);
  const filtereRepCount = useAppSelector(getReportFilteredCount);
  const reportLoading = useAppSelector(getReportLoading);
  const [reports, setReports] = useState(allReports);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filtering, setFiltering] = useState<GridFilterModel | null>(null);
  const [searchParams, setSearchParams] = useState<string>('');

  const typeParams = useMemo(
    () => ({
      status:
        type === EReportTabType.RESOLVED
          ? EReportStatus.RESOLVED
          : EReportStatus.NEW,
      onlyArchived: type === EReportTabType.ARCHIVED,
    }),
    [type]
  );

  const sortParams = useMemo(() => {
    if (sorting?.length && sorting[0].sort) {
      return {
        orderBy: sortedFields[sorting[0].field as TSortable],
        orderDirection: sorting[0].sort.toUpperCase() as ESortDirection,
      };
    }
    return {};
  }, [sorting]);

  const createFilterObject = (
    filter: GridFilterModel | null,
    search?: string
  ) => {
    if (search) {
      return {
        search,
      };
    } else if (filter?.items[0].field === 'target') {
      return {
        target: filter?.items[0].value,
      };
    } else if (filter?.items[0].field === 'reason') {
      return {
        reason: filter?.items[0].value,
      };
    } else if (
      filter?.items[0].field === 'reportObject' &&
      filter?.items[0].value
    ) {
      return {
        type: (
          filter?.items[0].value as string
        ).toLowerCase() as EReportObjectSend,
      };
    }
    return {};
  };

  const filterParams = useMemo(() => {
    if (filtering?.items.length) {
      return createFilterObject(filtering, searchParams);
    }
    return {};
  }, [filtering, searchParams]);

  useEffect(() => {
    shouldClearReports && setReports([]);
  }, [shouldClearReports]);

  useEffect(() => {
    dispatch(
      getReports({
        limit: 10,
        offset: 0,
        ...typeParams,
      })
    )
      .unwrap()
      .then((reportList) => {
        setReports(reportList?.data || []);
      });
  }, [dispatch, typeParams]);

  const statusChanging = useCallback((id: string) => {
    setReports((reps) => reps?.filter((item) => item.id !== id) || null);
  }, []);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSorting(sortModel);
      const sorts =
        sortModel?.length && sortModel[0].sort
          ? {
              orderBy: sortedFields[sortModel[0].field as TSortable],
              orderDirection: sortModel[0].sort.toUpperCase() as ESortDirection,
            }
          : {};
      dispatch(
        getReports({
          offset: 0,
          limit: reports?.length || 0,
          ...sorts,
          ...typeParams,
          ...filterParams,
        })
      )
        .unwrap()
        .then((responseReport) => {
          setReports(responseReport.data);
        });
    },
    [dispatch, reports?.length, typeParams, filterParams]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFiltering(filterModel);
      const filter = filterModel?.items.length
        ? createFilterObject(filterModel)
        : {};

      dispatch(
        getFilteredReports({
          offset: 0,
          limit: paginationModel.pageSize,
          ...sortParams,
          ...typeParams,
          ...filter,
        })
      )
        .unwrap()
        .then((responseReport) => {
          setReports(responseReport.data);
        });
    },
    [dispatch, sortParams, typeParams, paginationModel.pageSize]
  );

  const handlePaginationModel = useCallback(
    (pagination: GridPaginationModel) => {
      setPaginationModel(pagination);
      if (
        reports?.length &&
        (pagination.page + 1) * pagination.pageSize > reports?.length
      ) {
        dispatch(
          getMoreReports({
            offset: reports.length,
            limit: pagination.pageSize,
            ...typeParams,
            ...sortParams,
            ...filterParams,
          })
        )
          .unwrap()
          .then((responseReport) => {
            const reportsCount =
              reports.length + (responseReport?.data.length || 0);
            if (reportsCount < (pagination.page + 1) * pagination.pageSize) {
              setPaginationModel({
                ...pagination,
                page: Math.trunc(reportsCount / pagination.pageSize),
              });
            }
            setReports((reportList) =>
              reportList && responseReport
                ? [...reportList, ...responseReport.data]
                : []
            );
          });
      }
    },
    [reports?.length, dispatch, typeParams, sortParams, filterParams]
  );

  const search = useCallback(
    (searchStr: string) => {
      setSearchParams(searchStr);
      if (reports) {
        if (!searchStr) {
          setReports(allReports);
        } else {
          dispatch(
            getFilteredReports({
              limit: 10,
              offset: 0,
              search: searchStr,
              ...typeParams,
              ...sortParams,
            })
          )
            .unwrap()
            .then((reportList) => {
              setReports(reportList.data);
            });
        }
      }
    },
    [allReports, dispatch, reports, sortParams, typeParams]
  );

  const pageData = useMemo(() => {
    return reports?.slice(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
    );
  }, [reports, paginationModel]);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      valueFormatter: (date) => {
        return format(parseISO(date.value), 'dd/MM/yy');
      },
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'reportObject',
      headerName: 'Report Object',
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReportObject),
    },
    {
      field: 'target',
      headerName: 'Type',
      valueFormatter: ({ value }) => {
        return ChatType[value as EChatType];
      },
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        {
          value: EChatType.GLOBAL,
          label: ChatType[EChatType.GLOBAL],
        },
        {
          value: EChatType.HASHTAG,
          label: ChatType[EChatType.HASHTAG],
        },
        {
          value: EChatType.P2P,
          label: ChatType[EChatType.P2P],
        },
      ],
    },
    {
      field: 'id',
      headerName: 'Report ID',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'user',
      headerName: 'Reported User',
      renderCell: ({ row }) => {
        return (
          <Typography
            fontStyle={row?.user?.username ? 'normal' : 'italic'}
            sx={{
              opacity: row?.user?.username ? 1 : 0.5,
            }}
          >
            {row?.user?.username ? row?.user.username : 'Deleted user'}
          </Typography>
        );
      },
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'sender',
      headerName: 'Reported by',
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Typography
            fontStyle={row?.sender?.username ? 'normal' : 'italic'}
            sx={{
              opacity: row?.sender?.username ? 1 : 0.5,
            }}
          >
            {row?.sender?.username ? row?.sender.username : 'Deleted user'}
          </Typography>
        );
      },
    },
    {
      field: 'reason',
      headerName: 'Reason',
      valueFormatter: ({ value }) => {
        return capitalize((value as string).replace('-', ' '));
      },
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReason).map((value) => ({
        value,
        label: ReportReason[value],
      })),
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor: ReportStatusColor[type],
              '.MuiChip-icon': {
                color: theme.palette.neutral?.main,
              },
            }}
            label={type}
            icon={
              type === EReportTabType.RESOLVED ? <CheckCircle /> : undefined
            }
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 52,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <ContextMenuReport
            params={params}
            onIgnore={(id) => statusChanging(id)}
            onArchive={(id) => statusChanging(id)}
            onDelete={(id) => statusChanging(id)}
            onRestore={(id) => statusChanging(id)}
          />
        );
      },
    },
  ];

  return (
    <TableContent>
      {allReports?.length ? (
        <Box>
          <SearchInput
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            id="search-admin"
            size="small"
            placeholder="Search..."
            variant="standard"
            type="search"
            onChange={(e) => search(e.target.value)}
          />
        </Box>
      ) : null}
      <div
        style={{
          width: '100%',
          height: reports?.length && !reportLoading ? 'unset' : 250,
        }}
      >
        {allReports?.length || reportLoading ? (
          <DataGrid
            rows={rowsMapper(pageData || [])}
            columns={columns}
            slots={{
              columnMenu: GridFilterCustom,
              columnMenuIcon: CustomFilterIcon,
              noResultsOverlay: NoFilterResult,
            }}
            rowCount={
              Object.keys(filterParams).length > 0 && filtereRepCount
                ? filtereRepCount
                : totalCount || 0
            }
            pageSizeOptions={[10, 25, 50]}
            onRowClick={({ row }) =>
              navigate(`${ROUTES.DASHBOARD.REPORTS}/${row.id}`)
            }
            sx={{
              '.MuiDataGrid-filterIcon': {
                display: 'none',
              },
              [`.MuiDataGrid-columnHeader[data-field=${
                filtering?.items.length && filtering?.items[0].value
                  ? filtering?.items[0].field
                  : ''
              }]`]: {
                '.MuiDataGrid-menuIcon': {
                  display: 'flex',
                  visibility: 'unset',
                  marginRight: '0',
                  button: {
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'absolute',
                    right: 0,
                  },
                },
              },
            }}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            sortModel={sorting}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            loading={reportLoading}
          />
        ) : null}
        {!allReports?.length && !reportLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom="100px"
            marginTop="70px"
          >
            <img src={allReports?.length ? NoSearch : NoReport} />
            <EmptyReport>
              {allReports?.length
                ? 'No search result'
                : 'There are no reports for this category'}
            </EmptyReport>
          </Box>
        ) : null}
      </div>
    </TableContent>
  );
};
