import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import { BanParams, PaginatedBans, UnbanChatParams } from '@/types';

import { setBanLoading } from '../actions';
import { EBan } from '../constants';

export const getBans = createAsyncThunk(
  EBan.GET,
  async (banParams: BanParams, thunkApi) => {
    thunkApi.dispatch(setBanLoading(true));
    try {
      const { user_id, ...other } = banParams;
      const response = await client.get<PaginatedBans>(`/admin/reports`, {
        params: {
          ...other,
          userId: user_id,
        },
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setBanLoading(false));
    }
  }
);

export const getMoreBans = createAsyncThunk(
  EBan.GET_MORE,
  async (banParams: BanParams, thunkApi) => {
    thunkApi.dispatch(setBanLoading(true));
    try {
      const { user_id, ...other } = banParams;
      const response = await client.get<PaginatedBans>(`/admin/reports`, {
        params: {
          ...other,
          userId: user_id,
        },
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setBanLoading(false));
    }
  }
);

export const getFilteredBans = createAsyncThunk(
  EBan.GET_FILTERED,
  async (banParams: BanParams, thunkApi) => {
    thunkApi.dispatch(setBanLoading(true));
    try {
      const { user_id, ...other } = banParams;
      const response = await client.get<PaginatedBans>(`/admin/reports`, {
        params: {
          ...other,
          userId: user_id,
        },
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setBanLoading(false));
    }
  }
);

export const unbanChat = createAsyncThunk(
  EBan.UNBAN_CHAT,
  async (paramparams: UnbanChatParams, thunkApi) => {
    const { user_id, ...params } = paramparams;
    try {
      const response = await client.post(
        `/admin/users/${user_id}/unblock-for-target`,
        params
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);
