import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';

import { MainWrapper } from '@/components/styled';
import { ROUTES } from '@/constants';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <MainWrapper>
      <Helmet>
        <title>Page was not found</title>
      </Helmet>
      <Typography variant="h3">Ooops!</Typography>
      <Typography variant="h5">Page was not found</Typography>
      <Button onClick={() => navigate(ROUTES.BACK)} sx={{ margin: '10px' }}>
        Back
      </Button>
    </MainWrapper>
  );
};
