export enum EActionConfirm {
  DELETE_MESSAGE = 'delete-message',
  DELETE_REPORT = 'delete-report',
  BAN_APP = 'ban-from-app',
  UNBAN = 'unban',
  DELETE_USER = 'delete-user',
}

export interface ConfirmModal {
  action: EActionConfirm;
  title: string;
  text: (username?: string) => string;
  activeButton: string;
}

export const actionConfirmation: { [key in EActionConfirm]: ConfirmModal } = {
  [EActionConfirm.DELETE_MESSAGE]: {
    action: EActionConfirm.DELETE_MESSAGE,
    title: 'Delete The Message',
    text: () => 'Are you sure you want to delete the message?',
    activeButton: 'Delete Message',
  },
  [EActionConfirm.BAN_APP]: {
    action: EActionConfirm.BAN_APP,
    title: 'Ban From The App',
    text: (userName) =>
      `Are you sure you want to ban the ${userName} from the app?`,
    activeButton: 'Ban From The App',
  },
  [EActionConfirm.UNBAN]: {
    action: EActionConfirm.UNBAN,
    title: 'Unban The User',
    text: () => 'Are you sure you want to unban the user?',
    activeButton: 'Unban',
  },
  [EActionConfirm.DELETE_REPORT]: {
    action: EActionConfirm.DELETE_REPORT,
    title: 'Delete The Report',
    text: () => 'Are you sure you want to delete the report?',
    activeButton: 'Delete Report',
  },
  [EActionConfirm.DELETE_USER]: {
    action: EActionConfirm.DELETE_USER,
    title: 'Delete User',
    text: () =>
      'Are you sure you want to delete this user? This action will result in the temporary removal of all associated account data. This action cannot be undone.',
    activeButton: 'Delete User',
  },
};
