import { Helmet } from 'react-helmet';

import { EResetPassword } from '@/types/appProps';

import { SetPasswordForm } from '../../components/SetPasswordForm';

export const Invitation = () => {
  return (
    <>
      <Helmet>
        <title>Invitation</title>
      </Helmet>
      <SetPasswordForm page={EResetPassword.INVITATION} />
    </>
  );
};
