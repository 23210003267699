import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { PaginatedReports, Report } from '@/types';
import { ReportSlice } from '@/types/slices/reports';

import { EReport } from '../constants';
import { getFilteredReports, getMoreReports, getNewReports, getReports } from '../thunks';

const isArchDelete = (action: AnyAction) => {
  return (
    [EReport.DELETE_REPORT, EReport.ARCHIVE, EReport.RESTORE_REPORT].some(
      (act) => action.type.includes(act)
    ) && action.type.endsWith('/fulfilled')
  );
};

const isResolvingAction = (action: AnyAction) => {
  return (
    [
      EReport.KICK_USER,
      EReport.IGNORE,
      EReport.BAN_IN_APP,
      EReport.BAN_USER,
      EReport.DELETE_MESSAGE,
    ].some((act) => action.type.includes(act)) &&
    action.type.endsWith('/fulfilled')
  );
};

const initialState: ReportSlice = {
  reports: null,
  filteredReports: null,
  totalCount: null,
  newCount: null,
  filteredCount: null,
  reportLoading: false,
};

export const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    clearReports: (state) => {
      state.reports = null;
      state.filteredReports = null;
      state.totalCount = null;
      state.newCount = null;
    },
    setReportLoading: (state, { payload }) => {
      state.reportLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state) => {
        state.reports = [];
      })
      .addCase(getReports.fulfilled, (state, { payload, meta }) => {
        if (payload && !meta.arg.withoutSave) {
          const myReports = payload.data;
          state.reports = myReports;
          state.totalCount = payload.meta.totalCount;
        }
      })
      .addCase(getMoreReports.fulfilled, (state, { payload, meta }) => {
        if (payload && !meta.arg.withoutSave) {
          const myReports = payload.data as Report[];
          state.reports = state.reports
            ? [...state.reports, ...myReports]
            : myReports;
        }
      })
      .addCase(getFilteredReports.fulfilled, (state, { payload, meta }) => {
        if (payload && !meta.arg.withoutSave) {
          const myReports = payload.data as Report[];
          state.filteredReports = state.filteredReports
            ? [...state.filteredReports, ...myReports]
            : myReports;
          state.filteredCount = payload.meta.totalCount
            ? payload.meta.totalCount
            : meta.arg.limit > myReports.length
            ? myReports.length
            : payload.meta.totalPages *
              (payload.meta.perPage || meta.arg.limit);
        }
      })
      .addCase(getNewReports.fulfilled, (state, { payload }) => {
        state.newCount = (payload as PaginatedReports).meta.totalCount;
      })
      .addMatcher(isArchDelete, (state, { meta }) => {
        state.totalCount = state.totalCount
          ? state.totalCount - 1
          : state.totalCount;

        state.filteredCount = state.filteredCount
          ? state.filteredCount - 1
          : state.filteredCount;

        if (state.reports) {
          state.reports = [
            ...state.reports.filter((report) => meta.arg !== report.id),
          ];
        }

        if (state.filteredReports) {
          state.reports = [
            ...state.filteredReports.filter(
              (report) => meta.arg.id !== report.id
            ),
          ];
        }
      })
      .addMatcher(isResolvingAction, (state, { meta }) => {
        state.newCount = state.newCount ? state.newCount - 1 : state.newCount;
        state.totalCount = state.totalCount
          ? state.totalCount - 1
          : state.totalCount;

        state.filteredCount = state.filteredCount
          ? state.filteredCount - 1
          : state.filteredCount;

        if (state.reports) {
          state.reports = [
            ...state.reports.filter((report) => meta.arg !== report.id),
          ];
        }

        if (state.filteredReports) {
          state.reports = [
            ...state.filteredReports.filter(
              (report) => meta.arg.id !== report.id
            ),
          ];
        }
      });
  },
});
