import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import {
  BanUserParams,
  EReportStatus,
  PaginatedReports,
  Report,
  ReportSaveParams,
} from '@/types';

import { setReportLoading } from '../actions';
import { EReport } from '../constants';

export const getNewReports = createAsyncThunk(
  EReport.GET_NEW,
  async (_, thunkApi) => {
    thunkApi.dispatch(setReportLoading(true));
    const reportParams: ReportSaveParams = {
      limit: 10,
      offset: 0,
      status: EReportStatus.NEW,
    };
    try {
      const response = await client.get<PaginatedReports>('/admin/reports', {
        params: reportParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setReportLoading(false));
    }
  }
);

export const getReports = createAsyncThunk(
  EReport.GET,
  async (reportParams: ReportSaveParams, thunkApi) => {
    thunkApi.dispatch(setReportLoading(true));
    try {
      const response = await client.get<PaginatedReports>('/admin/reports', {
        params: reportParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setReportLoading(false));
    }
  }
);

export const getMoreReports = createAsyncThunk(
  EReport.GET_MORE,
  async (reportParams: ReportSaveParams, thunkApi) => {
    thunkApi.dispatch(setReportLoading(true));
    try {
      const response = await client.get<PaginatedReports>('/admin/reports', {
        params: reportParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setReportLoading(false));
    }
  }
);

export const getFilteredReports = createAsyncThunk(
  EReport.GET_FILTERED,
  async (reportParams: ReportSaveParams, thunkApi) => {
    thunkApi.dispatch(setReportLoading(true));
    try {
      const response = await client.get<PaginatedReports>('/admin/reports', {
        params: reportParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setReportLoading(false));
    }
  }
);

export const getReportById = createAsyncThunk(
  EReport.GET_BY_ID,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(setReportLoading(true));
    try {
      const response = await client.get<Report>(`/admin/reports/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setReportLoading(false));
    }
  }
);

export const ignoreReport = createAsyncThunk(
  EReport.IGNORE,
  async (id: string, thunkApi) => {
    try {
      const response = await client.post(`/admin/reports/${id}/ignore`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const archiveReport = createAsyncThunk(
  EReport.ARCHIVE,
  async (id: string, thunkApi) => {
    try {
      const response = await client.post(`/admin/reports/${id}/archive`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const kickUser = createAsyncThunk(
  EReport.KICK_USER,
  async (id: string, thunkApi) => {
    try {
      const response = await client.post(`/admin/reports/${id}/user/kick`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const banUser = createAsyncThunk(
  EReport.BAN_USER,
  async (params: BanUserParams, thunkApi) => {
    try {
      const response = await client.post(
        `/admin/reports/${params.id}/user/block`,
        { duration: params.duration }
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const banInApp = createAsyncThunk(
  EReport.BAN_IN_APP,
  async (params: BanUserParams, thunkApi) => {
    try {
      const response = await client.post(
        `/admin/reports/${params.id}/user/block/system`,
        params.duration ? { duration: params.duration } : undefined
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const deleteMessage = createAsyncThunk(
  EReport.DELETE_MESSAGE,
  async (id: string, thunkApi) => {
    try {
      const response = await client.delete(`/admin/reports/${id}/message`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const deleteReport = createAsyncThunk(
  EReport.DELETE_REPORT,
  async (id: string, thunkApi) => {
    try {
      const response = await client.delete(`/admin/reports/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const restoreArchivedReport = createAsyncThunk(
  EReport.RESTORE_REPORT,
  async (id: string, thunkApi) => {
    try {
      const response = await client.delete(`/admin/reports/${id}/archive`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);
