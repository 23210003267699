import { Store } from '@reduxjs/toolkit';
import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from 'axios';

import {
  ROUTES,
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from '@/constants';
import { LoggerService } from '@/services';
import { clearUser } from '@/store/actions';
import { AuthTokens } from '@/types';
import { removeAuthTokens, setAuthTokens } from '@/utils';

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
  store: Store
): AxiosInstance {
  // Watch if refresh token request has been sent to avoid multiple parelel requests
  let isRefreshing = false;
  // Keep track of requests that failed due to 401
  let failedQueue: PromiseConstructor[] = [];

  const processQueue = (error: Error | null, token: string | null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const onRequest = (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY);

    if (token && !config.url?.includes('/admin/auth/refresh-token')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (
      config.url?.includes('/admin/auth/refresh-token') &&
      refreshToken
    ) {
      config.headers['Refresh-Auth'] = refreshToken;
    }

    return config;
  };

  const onRequestError = (error: AxiosError) => {
    LoggerService.log(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  };

  const onResponse = (response: AxiosResponse) => {
    LoggerService.log(`[response] [${JSON.stringify(response)}]`);
    return response;
  };

  const onResponseError = (client: AxiosInstance) => (error: AxiosError) => {
    const originalRequest = {
      ...error.config,
      retry: false,
    };

    const isUnauthorized =
      error.response?.status === HttpStatusCode.Unauthorized ||
      error.response?.status === HttpStatusCode.UpgradeRequired;

    if (isUnauthorized && originalRequest.url?.includes('auth/refresh-token')) {
      window.location.replace(ROUTES.AUTH.LOGIN);
      removeAuthTokens();
      store.dispatch(clearUser());
      return Promise.reject(error);
    }

    if (isUnauthorized && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject } as PromiseConstructor);
        }).then((token) => {
          return client({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            },
          }).catch((err) => {
            return Promise.reject(err);
          });
        });
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        client
          .post<void, AxiosResponse<AuthTokens>>('/admin/auth/refresh-token')
          .then((res) => {
            const accessToken = res.data.accessToken;
            const refreshToken = res.data.refreshToken;

            setAuthTokens(accessToken, refreshToken);

            client.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.accessToken;

            processQueue(null, res.data.accessToken);
            resolve(client(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(
    onResponse,
    onResponseError(axiosInstance)
  );

  return axiosInstance;
}
