import { Box, Card, CardContent, CardHeader, Grid, styled, Typography } from '@mui/material';


export const AuthLayoutGrid = styled(Grid)(({ theme }) => `
  background-color: ${theme.palette.neutral?.light};
  height: 100dvh;
  ${theme.breakpoints.down('md')} {
    padding: 16px;
  }
  ${theme.breakpoints.down('sm')} {
    height: max(100dvh, 550px);
  }
`);

export const ContentAreaCard = styled(Card)(({ theme }) => `
  border-radius: 10px;
  gap: 30px;
  box-shadow: none;

  ${theme.breakpoints.up('md')} {
    padding: 20px 60px 60px 60px;
  }

  ${theme.breakpoints.down('md')} {
    padding: 20px 30px 30px 30px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 20px;
    gap: 0;
  }
`
);

export const AuthTitle = styled(Typography)`
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.1px;
  text-align: center;
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const AuthCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  & .MuiCardHeader-avatar {
    margin-right: 0;
  }
`;

export const AuthCardHeaderWrapper = styled(Box)(({ theme }) => `
  padding: 0px 16px 30px;
  ${theme.breakpoints.down('sm')} {
    padding: 0px 0px 20px;
  }
`
);

export const AuthCardContent = styled(CardContent)`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`;