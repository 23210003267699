import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const selectApp = (state: RootState) => state.app;

export const getShowGlobalSpinner = createSelector(
  [selectApp],
  (appState) => appState.showGlobalSpinner
);

export const getShowSideMenu = createSelector(
  [selectApp],
  (appState) => appState.showSideMenu
);
