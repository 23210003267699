import { Box, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { showSideMenu } from '@/store/actions';
import { getShowSideMenu, getUserLoaded } from '@/store/selectors';
import { getNewReports } from '@/store/thunks';
import { theme } from '@/theme';

import { AuthUserGuard } from '../guards/NotAuthorized';
import { Header, SideMenu } from './components';
import { ContentBox, Main, SideMenuHeader } from './styled';


export const DashboardLayout = () => {
  const dispatch = useAppDispatch();
  const openSideMenu = useAppSelector(getShowSideMenu);
  const isUserLoaded = useAppSelector(getUserLoaded);
  const isMobileScrren = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(getNewReports());
  }, [dispatch]);

  useEffect(() => {
    if (isMobileScrren) {
      dispatch(showSideMenu(false));
    } else {
      dispatch(showSideMenu(true));
    }
  }, [dispatch, isMobileScrren])

  return (
    <Box display="flex">
      <Header isSideMenuOpen={openSideMenu} />
      <SideMenu isOpen={openSideMenu} />
      {isUserLoaded ? <AuthUserGuard /> : null}

      <Main open={openSideMenu} isMobile={isMobileScrren}>
        <SideMenuHeader />
        <ContentBox>
          <Outlet />
        </ContentBox>
      </Main>
    </Box>
  );
};
