import { LogoutOutlined, Menu } from '@mui/icons-material';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AvatarHeader } from '@/components/styled/Avatar';
import { ROUTES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { showSideMenu } from '@/store/actions';
import { getUserName } from '@/store/selectors';
import { logoutUser } from '@/store/thunks';
import { theme } from '@/theme';
import { stringAvatar } from '@/utils';

import LogoPicture from '../../../../assets/pictures/logo.png';
import { AppBar, AppToolbar, BrendIcon } from '../../styled';

type Props = {
  isSideMenuOpen: boolean;
};

export const Header = ({ isSideMenuOpen }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userName = useAppSelector(getUserName);
  const isMobileScrren = useMediaQuery(theme.breakpoints.down('md'));
  const isPhoneScrren = useMediaQuery(theme.breakpoints.down('sm'));

  const logoutHandle = useCallback(() => {
    dispatch(logoutUser())
      .unwrap()
      .then(() => {
        navigate(ROUTES.AUTH.LOGIN);
      });
  }, [dispatch, navigate]);

  const toggleSideMenu = useCallback(() => {
    dispatch(showSideMenu(!isSideMenuOpen));
  }, [dispatch, isSideMenuOpen]);

  return (
    <AppBar position="fixed" open={isSideMenuOpen} isMobile={isMobileScrren}>
      <AppToolbar>
        {isMobileScrren ?
          <Box display="flex" sx={{width: '40%', justifyContent: 'flex-start'}}>
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              sx={{
                color: isSideMenuOpen ? theme.palette.primary.main : theme.palette.grey[500],
              }}
              onClick={() => toggleSideMenu()}
            >
              <Menu />
            </IconButton>
          </Box>
          : null
        }
        <BrendIcon>
          <img src={LogoPicture} alt="Logo" />
        </BrendIcon>

        {userName ? (
          <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ flexGrow: 0, width: '40%', }}>
            <AvatarHeader sx={{
                marginRight: isMobileScrren ? '10px' : '21px'
              }}
              {...stringAvatar(userName)}
            />
            {isPhoneScrren ? null :
              <Box sx={{ marginRight: isMobileScrren ? '10px' : '20px' }}>{userName}</Box>
            }
            <IconButton aria-label="delete" onClick={() => logoutHandle()}>
              <LogoutOutlined />
            </IconButton>
          </Box>
        ) : null}
      </AppToolbar>
    </AppBar>
  );
};