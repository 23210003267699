import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const selectUser = (state: RootState) => state.admins;

export const getAdminList = createSelector(
  [selectUser],
  ({ admins }) => admins
);

export const getEditedUser = createSelector(
  [selectUser],
  ({ admins, editedAdminId }) => admins?.find(({ id }) => editedAdminId === id)
);
