import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToastService } from '@/services';
import { AuthSlice, ErrorDto, RejectedAction, User } from '@/types';

import { changeMyName, loginUser, me } from '../thunks';

const initialState: AuthSlice = {
  user: null,
  loading: true,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    setLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(me.fulfilled, (state, { payload }) => {
        state.user = payload as User;
        state.loading = false;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeMyName.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
      })
      .addMatcher(
        (action): action is RejectedAction => {
          return action.type.endsWith('/rejected');
        },
        (_, { payload }) => {
          ToastService.error((payload as ErrorDto).error.message);
        }
      );
  },
});
