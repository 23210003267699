import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import {
  AdminUserName,
  AdminUserPassword,
  AuthTokens,
  User,
  UserAcceptInvite,
  UserLogin,
  UserResetPassword,
} from '@/types';
import { removeAuthTokens, setAuthTokens } from '@/utils';

import { clearUser, showSpinner } from '../actions';
import { EAuth } from '../constants';

export const loginUser = createAsyncThunk(
  EAuth.LOGIN,
  async (payload: UserLogin, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.post<AuthTokens>(
        '/admin/auth/login',
        payload
      );
      const { accessToken, refreshToken } = response.data;

      setAuthTokens(accessToken, refreshToken);
      await thunkApi.dispatch(me());
      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const me = createAsyncThunk(EAuth.ME, async (_, thunkApi) => {
  thunkApi.dispatch(showSpinner(true));

  try {
    const response = await client.get<User>('/admin/me');

    return response.data;
  } catch (error) {
    const err = error as AxiosError | Error;
    return thunkApi.rejectWithValue(
      'response' in err && err?.response?.data
        ? { error: err?.response?.data }
        : { error: err }
    );
  } finally {
    thunkApi.dispatch(showSpinner(false));
  }
});

export const logoutUser = createAsyncThunk(
  EAuth.LOGOUT,
  async (_, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      removeAuthTokens();
      thunkApi.dispatch(clearUser());
      return null;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  EAuth.FORGOT_PASSWORD,
  async (email: string, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const res = await client.post<AuthTokens>('/admin/auth/reset-password', {
        email,
      });
      return res.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const resetPassword = createAsyncThunk(
  EAuth.RESET_PASSWORD,
  async (passData: UserResetPassword, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const res = await client.patch('/admin/auth/reset-password', passData);
      return res.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const acceptInvite = createAsyncThunk(
  EAuth.ACCEPT_INVITE,
  async (passData: UserResetPassword, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    const data: UserAcceptInvite = {
      newPassword: passData.newPassword,
      invitationToken: passData.resetToken,
    };
    try {
      const res = await client.post('/admin/invitation/accept', data);
      return res.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && (err as AxiosError)?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const changeMyName = createAsyncThunk(
  EAuth.CHANGE_NAME,
  async (userName: AdminUserName, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.patch<User>('/admin/me', userName);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const changeMyPassword = createAsyncThunk(
  EAuth.CHANGE_NAME,
  async (data: AdminUserPassword, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.patch<User>('/admin/me', {
        oldPassword: data.oldPassword,
        password: data.password,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);
