import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppSlice } from '@/types';

const initialState: AppSlice = {
  showGlobalSpinner: false,
  showSideMenu: false,
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    showSpinner: (state, { payload }: PayloadAction<boolean>) => {
      state.showGlobalSpinner = payload;
    },
    showSideMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.showSideMenu = payload;
    },
  },
});
