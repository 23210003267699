import { object, string } from 'yup';

import { FIELD_IS_REQUIRED } from '@/constants';

const MAX_FIRST_NAME = 'Name cannot exceed 50 characters';
const MAX_LAST_NAME = 'Last name cannot exceed 50 characters';

export const userNameSchema = object({
  firstName: string().required(FIELD_IS_REQUIRED).max(50, MAX_FIRST_NAME),
  lastName: string().required(FIELD_IS_REQUIRED).max(50, MAX_LAST_NAME),
});
