import { createSlice } from '@reduxjs/toolkit';

import { ClientUser, EClientUserStatus, UserSlice } from '@/types';

import {
  banUserInApp,
  deleteUser,
  getFilteredUsers,
  getMoreUsers,
  getUsers,
  unbanUserInApp,
} from '../thunks';

const initialState: UserSlice = {
  users: null,
  filteredUsers: null,
  totalCount: null,
  filteredCount: null,
  contentLoading: false,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = null;
      state.filteredUsers = null;
      state.totalCount = null;
    },
    setUserLoader: (state, { payload }) => {
      state.contentLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          const myUsers = payload.data;
          state.users = myUsers;
          state.totalCount = payload.meta.totalCount;
        }
      })
      .addCase(getMoreUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          const myUsers = payload.data as ClientUser[];
          state.users = state.users ? [...state.users, ...myUsers] : myUsers;
        }
      })
      .addCase(getFilteredUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          const myUsers = payload.data as ClientUser[];
          state.filteredUsers = state.filteredUsers
            ? [...state.filteredUsers, ...myUsers]
            : myUsers;
          state.filteredCount = payload.meta.totalCount;
        }
      })
      .addCase(deleteUser.fulfilled, (state, { meta }) => {
        if (state.users && meta.arg) {
          state.users = state.users.filter(({ id }) => id !== meta.arg);
          state.totalCount = (state.totalCount || 0) - 1;
        }
        if (state.filteredUsers && meta.arg) {
          state.filteredUsers = state.filteredUsers.filter(
            ({ id }) => id !== meta.arg
          );
          state.filteredCount = (state.filteredCount || 0) - 1;
        }
      })
      .addCase(unbanUserInApp.fulfilled, (state, { meta }) => {
        state.users = state.users
          ? [
              ...state.users.map((item) =>
                item.id !== meta.arg
                  ? item
                  : {
                      ...item,
                      status: EClientUserStatus.ACTIVE,
                    }
              ),
            ]
          : null;

        state.filteredUsers = state.filteredUsers
          ? [
              ...state.filteredUsers.map((item) =>
                item.id !== meta.arg
                  ? item
                  : {
                      ...item,
                      status: EClientUserStatus.ACTIVE,
                    }
              ),
            ]
          : null;
      })
      .addCase(banUserInApp.fulfilled, (state, { meta }) => {
        state.users = state.users
          ? [
              ...state.users.map((item) =>
                item.id !== meta.arg
                  ? item
                  : {
                      ...item,
                      status: EClientUserStatus.BAN,
                    }
              ),
            ]
          : null;

        state.filteredUsers = state.filteredUsers
          ? [
              ...state.filteredUsers.map((item) =>
                item.id !== meta.arg
                  ? item
                  : {
                      ...item,
                      status: EClientUserStatus.BAN,
                    }
              ),
            ]
          : null;
      });
  },
});
