import { Box, capitalize } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ContextMenuBan } from '@/components/common/ContextMenuReport/ContextMenuBan';
import {
  CustomFilterIcon,
  GridFilterCustom,
} from '@/components/common/GridFilterCustom';
import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';
import { StatusChip } from '@/components/styled/Chip';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import { getBans, getFilteredBans, getMoreBans } from '@/store/thunks';
import { theme } from '@/theme';
import { SolutionColor } from '@/theme/theming/statusesTheme';
import {
  BanReport,
  ChatType,
  ClientUser,
  EChatType,
  EReason,
  EReportObject,
  EReportObjectSend,
  EReportStatus,
  ESolution,
  ESortDirection,
  PaginatedBans,
  ReportReason,
  ReportSolution,
} from '@/types';

import NoReport from '../../../../assets/pictures/ReportOff.png';
import NoSearch from '../../../../assets/pictures/Search.svg';
import {
  TableContent,
  TablePreHeader,
  TableWrapper,
  TitlePreHeader,
} from '../../styled/TableWrapper';
import { sortedFields, TSortable } from './constants/sortModels';

// eslint-disable-next-line react/no-multi-comp
const NoFilterResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <img src={NoSearch} />
      <EmptyReport>No report found</EmptyReport>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const NoResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <img src={NoReport} />
      <EmptyReport>There are no bans for this user</EmptyReport>
    </Box>
  );
};

const rowsMapper = (bans: Array<BanReport>, user: ClientUser) =>
  bans.map((ban) => ({
    id: ban.id,
    createdAt: ban.createdAt,
    reportObject: ban.context?.messageId
      ? EReportObject.MESSAGE
      : EReportObject.USER,
    target: ban.target,
    targetName: ban.targetChat?.chatName || '-',
    reason: ban.reason || '-',
    solution: ban.solution || '',
    user: user,
    targetId: ban.targetId,
  }));

interface BanHistoryProps {
  user: ClientUser;
}

// eslint-disable-next-line react/no-multi-comp
export const BanHistory: FC<BanHistoryProps> = ({ user }) => {
  const dispatch = useAppDispatch();
  const [bans, setBans] = useState<BanReport[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filtering, setFiltering] = useState<GridFilterModel | null>(null);

  const userParams = useMemo(() => {
    return {
      user_id: user.id,
      activeBlocksFirst: true,
      status: EReportStatus.RESOLVED,
    };
  }, [user.id]);

  const sortParams = useMemo(() => {
    if (sorting?.length && sorting[0].sort) {
      return {
        orderBy: sortedFields[sorting[0].field as TSortable],
        orderDirection: sorting[0].sort.toUpperCase() as ESortDirection,
      };
    }
    return {};
  }, [sorting]);

  const createFilterObject = (filter: GridFilterModel) => {
    if (filter?.items[0].field === 'target') {
      return {
        target: filter?.items[0].value,
      };
    } else if (filter?.items[0].field === 'reason') {
      return {
        reason: filter?.items[0].value,
      };
    } else if (filter?.items[0].field === 'solution') {
      return {
        solution: filter?.items[0].value,
      };
    } else if (
      filter?.items[0].field === 'reportObject' &&
      filter?.items[0].value
    ) {
      return {
        type: (
          filter?.items[0].value as string
        ).toLowerCase() as EReportObjectSend,
      };
    }
    return {};
  };

  const filterParams = useMemo(() => {
    if (filtering?.items.length) {
      return createFilterObject(filtering);
    }
    return {};
  }, [filtering]);

  useEffect(() => {
    dispatch(
      getBans({
        limit: 10,
        offset: 0,
        ...userParams,
      })
    )
      .unwrap()
      .then((banList) => {
        if (banList) {
          setBans(banList?.data);
          setTotalCount(banList?.meta.totalCount);
        }
      })
      .catch((err) => {
        ToastService.error(err.message);
      });
  }, [dispatch, userParams]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSorting(sortModel);
      const sorts =
        sortModel?.length && sortModel[0].sort
          ? {
              orderBy: sortedFields[sortModel[0].field as TSortable],
              orderDirection: sortModel[0].sort.toUpperCase() as ESortDirection,
            }
          : {};
      dispatch(
        getBans({
          offset: 0,
          limit: bans?.length || 0,
          ...sorts,
          ...userParams,
          ...filterParams,
        })
      )
        .unwrap()
        .then((resultList) => {
          if (resultList) {
            setBans(resultList.data);
          }
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, filterParams, userParams, bans?.length]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFiltering(filterModel);

      const filter = filterModel?.items.length
        ? createFilterObject(filterModel)
        : {};

      dispatch(
        getFilteredBans({
          offset: 0,
          limit: 10,
          ...sortParams,
          ...userParams,
          ...filter,
        })
      )
        .unwrap()
        .then((filteredBan: PaginatedBans | null) => {
          if (filteredBan) {
            setBans(filteredBan.data);
            setTotalCount(filteredBan.meta.totalCount);
          }
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, userParams, sortParams]
  );

  const handlePaginationModel = useCallback(
    (pagination: GridPaginationModel) => {
      setPaginationModel(pagination);
      if (
        bans?.length &&
        (pagination.page + 1) * pagination.pageSize > bans?.length
      ) {
        dispatch(
          getMoreBans({
            offset: bans.length,
            limit: pagination.pageSize,
            ...userParams,
            ...sortParams,
            ...filterParams,
          })
        )
          .unwrap()
          .then((resultList) => {
            if (resultList) {
              setBans((prev) =>
                prev?.length ? [...prev, ...resultList.data] : resultList.data
              );
            }
          })
          .catch((err) => {
            ToastService.error(err.message);
          });
      }
    },
    [bans?.length, dispatch, userParams, sortParams, filterParams]
  );

  const handleUnban = useCallback((id: string) => {
    setBans((ban) => ban?.filter((item) => item.id !== id) || null);
  }, []);

  const pageData = useMemo(() => {
    return bans?.slice(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
    );
  }, [bans, paginationModel]);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      valueFormatter: (date) => {
        return format(parseISO(date.value), 'dd/MM/yy');
      },
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'reportObject',
      headerName: 'Report Object',
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReportObject),
    },
    {
      field: 'target',
      headerName: 'Chat Type',
      valueFormatter: ({ value }) => {
        return ChatType[value as EChatType];
      },
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        {
          value: EChatType.GLOBAL,
          label: ChatType[EChatType.GLOBAL],
        },
        {
          value: EChatType.HASHTAG,
          label: ChatType[EChatType.HASHTAG],
        },
        {
          value: EChatType.P2P,
          label: ChatType[EChatType.P2P],
        },
      ],
    },
    {
      field: 'targetName',
      headerName: 'Chat Name',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'reason',
      headerName: 'Subject',
      valueFormatter: ({ value }) => {
        if (value === '-') {
          return '-';
        }
        return capitalize((value as string).replace('-', ' '));
      },
      flex: 1,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReason).map((value) => ({
        value,
        label: ReportReason[value],
      })),
    },
    {
      field: 'solution',
      headerName: 'Solution',
      valueFormatter: ({ value }) => {
        return capitalize((value as string).replace('-', ' '));
      },
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      valueOptions: Object.values(ESolution).map((value) => ({
        value,
        label: ReportSolution[value],
      })),
      renderCell: (params) => {
        return (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor: params.row.solution
                ? SolutionColor[params.row.solution as ESolution]
                : 'transparent',
              '.MuiChip-icon': {
                color: theme.palette.neutral?.main,
              },
            }}
            label={
              params.row.solution
                ? ReportSolution[params.row.solution as ESolution]
                : '-'
            }
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 52,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.solution &&
          params.row.solution === ESolution.BANNED_FROM_CHAT
        ) {
          return <ContextMenuBan params={params} onUnban={handleUnban} />;
        }
        return null;
      },
    },
  ];

  return (
    <TableWrapper>
      <TablePreHeader
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TitlePreHeader>Ban History</TitlePreHeader>
      </TablePreHeader>
      <TableContent>
        <div style={{ width: '100%', height: bans?.length ? 'unset' : 250 }}>
          <DataGrid
            rows={rowsMapper(pageData || [], user)}
            columns={columns}
            slots={{
              noResultsOverlay: NoFilterResult,
              noRowsOverlay: Object.keys(filterParams).length
                ? NoFilterResult
                : NoResult,
              columnMenu: GridFilterCustom,
              columnMenuIcon: CustomFilterIcon,
            }}
            rowCount={totalCount || 0}
            pageSizeOptions={[10, 25, 50]}
            // TODO uncomment when will be added page Ban Detail
            // onRowClick={({ row }) =>
            //   navigate(`${ROUTES.DASHBOARD.REPORTS}/${row.id}`)
            // }
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            sortModel={sorting}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            sx={{
              '.MuiDataGrid-filterIcon': {
                display: 'none',
              },
              [`.MuiDataGrid-columnHeader[data-field=${
                filtering?.items.length && filtering?.items[0].value
                  ? filtering?.items[0].field
                  : ''
              }]`]: {
                '.MuiDataGrid-menuIcon': {
                  display: 'flex',
                  visibility: 'unset',
                  marginRight: '0',
                  button: {
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'absolute',
                    right: 0,
                  },
                },
              },
            }}
          />
        </div>
      </TableContent>
    </TableWrapper>
  );
};
