import { Box, Popover, styled } from '@mui/material';

export const ContextMenu = styled(Popover)`
  .MuiPopover-paper {
    width: 276px;
    padding: 8px 0;
  }
`;

export const MenuTitle = styled(Box)`
  padding: 6px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  margin-bottom: 6px;
`;
