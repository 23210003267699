export enum EReport {
  GET = 'report/getReports',
  GET_MORE = 'report/getMoreReports',
  GET_FILTERED = 'report/getFiltered',
  GET_BY_ID = 'report/getReportById',
  GET_NEW = 'report/getNewReport',
  IGNORE = 'report/ignore',
  ARCHIVE = 'report/archive',
  DELETE_REPORT = 'report/deleteReport',
  DELETE_MESSAGE = 'report/deleteMessage',
  KICK_USER = 'report/kickUser',
  BAN_USER = 'report/banUser',
  BAN_IN_APP = 'report/banInApp',
  RESTORE_REPORT = 'report/restoreReport',
}
