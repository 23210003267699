import { object, string } from 'yup';

import { FIELD_IS_REQUIRED, MAX_MIN_PASSWORD, PASSWORD_SYMBOLS, PASSWORDS_DO_NOT_MATCH } from '@/constants';


export const changePasswordSchema = object({
  oldPassword: string()
    .required(FIELD_IS_REQUIRED)
    .min(6, MAX_MIN_PASSWORD(8, 20))
    .max(20, MAX_MIN_PASSWORD(8, 20))
    .matches(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).+$/gm, PASSWORD_SYMBOLS),
  password: string()
    .required(FIELD_IS_REQUIRED)
    .min(6, MAX_MIN_PASSWORD(8, 20))
    .max(20, MAX_MIN_PASSWORD(8, 20))
    .matches(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).+$/gm, PASSWORD_SYMBOLS),
  confirmPassword: string()
    .required(FIELD_IS_REQUIRED)
    .min(6, MAX_MIN_PASSWORD(8, 20))
    .max(20, MAX_MIN_PASSWORD(8, 20))
    .matches(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).+$/gm, PASSWORD_SYMBOLS)
    .test('passwords-match', PASSWORDS_DO_NOT_MATCH, function (value) {
      return this.parent.password === value;
    }),
});