import {
  Archive,
  Cancel,
  Delete,
  MoreHoriz,
  UploadFile,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useEffect, useState } from 'react';

import {
  CancelButton,
  ConfirmButton,
  ConfirmText,
  ConfirmTitle,
} from '@/components/styled/ConfimModal';
import { ContextMenu, MenuTitle } from '@/components/styled/Menu';
import {
  actionConfirmation,
  ConfirmModal,
  EActionConfirm,
} from '@/constants/actionConfirm';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import {
  archiveReport,
  deleteReport,
  ignoreReport,
  restoreArchivedReport,
} from '@/store/thunks';
import { theme } from '@/theme';
import { EReportStatusSend, Report } from '@/types';

import WarningSign from '../../../assets/pictures/PopupBadge.svg';
import { Modal } from '../Modal';
import { WarningBox } from '../Modal/styled';

interface ContextMenuReportProps {
  params: GridRenderCellParams;
  onIgnore?: (id: string) => void;
  onArchive?: (id: string) => void;
  onDelete?: (id: string) => void;
  onRestore?: (id: string) => void;
}

export const ContextMenuReport: FC<ContextMenuReportProps> = ({
  params,
  onIgnore,
  onArchive,
  onDelete,
  onRestore,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [report, setReport] = useState<Report | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<ConfirmModal>(
    actionConfirmation[EActionConfirm.UNBAN]
  );

  useEffect(() => {
    setReport(params.row);
  }, [params.row]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const ignore = useCallback(() => {
    dispatch(ignoreReport(params.row.id.toString()))
      .unwrap()
      .then(() => {
        onIgnore && onIgnore(params.row.id);
        handleClose();
      })
      .catch((err) => {
        ToastService.error(err);
      });
  }, [dispatch, onIgnore, params.row.id]);

  const archive = useCallback(() => {
    dispatch(archiveReport(params.row.id))
      .unwrap()
      .then(() => {
        onArchive && onArchive(params.row.id);
        handleClose();
      })
      .catch((err) => {
        ToastService.error(err);
      });
  }, [dispatch, onArchive, params.row.id]);

  const restore = useCallback(() => {
    dispatch(restoreArchivedReport(params.row.id))
      .unwrap()
      .then(() => {
        onRestore && onRestore(params.row.id);
        handleClose();
      })
      .catch((err) => {
        ToastService.error(err);
      });
  }, [dispatch, onRestore, params.row.id]);

  const openConfirmModal = useCallback((action: EActionConfirm) => {
    setModal(actionConfirmation[action]);
    setIsOpenModal(true);
  }, []);

  const deleteRep = useCallback(() => {
    dispatch(deleteReport(params.row.id))
      .unwrap()
      .then(() => {
        onDelete && onDelete(params.row.id);
        handleClose();
      })
      .catch((err) => {
        ToastService.error(err);
      });
  }, [dispatch, params.row.id, onDelete]);

  const modalClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const confirmActionHandle = useCallback(
    (action: EActionConfirm) => {
      switch (action) {
        case EActionConfirm.DELETE_REPORT: {
          deleteRep();
          break;
        }
        default:
          break;
      }
      modalClose();
    },
    [deleteRep, modalClose]
  );

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <MoreHoriz />
      </IconButton>
      <ContextMenu
        id={`context-menu-${params.row.id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {report?.archivedAt ? (
          <>
            <MenuItem onClick={() => restore()}>
              <ListItemIcon>
                <UploadFile fontSize="small" />
              </ListItemIcon>
              <ListItemText>Restore Report</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => openConfirmModal(EActionConfirm.DELETE_REPORT)}
            >
              <ListItemIcon>
                <Delete color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ color: theme.palette.error.main }}>
                Delete Report
              </ListItemText>
            </MenuItem>
          </>
        ) : !report?.archivedAt &&
          report?.status === EReportStatusSend.RESOLVED ? (
          <MenuItem onClick={() => archive()}>
            <ListItemIcon>
              <Archive fontSize="small" />
            </ListItemIcon>
            <ListItemText>Archive</ListItemText>
          </MenuItem>
        ) : (
          <>
            <MenuTitle>User actions</MenuTitle>
            <MenuItem onClick={() => ignore()}>
              <ListItemIcon>
                <Cancel fontSize="small" />
              </ListItemIcon>
              <ListItemText>Ignore</ListItemText>
            </MenuItem>
          </>
        )}
      </ContextMenu>
      {report?.user || modal.action === EActionConfirm.DELETE_REPORT ? (
        <Modal isOpen={isOpenModal} hasCloseIcon onClose={modalClose}>
          <WarningBox>
            <img src={WarningSign} />
            <ConfirmTitle>{modal.title}</ConfirmTitle>
            <ConfirmText>
              {modal.text(report?.user?.username || '')}
            </ConfirmText>
            <Box display="flex" justifyContent="space-between" width="100%">
              <CancelButton
                className="cancel"
                variant="contained"
                onClick={() => modalClose()}
              >
                Cancel
              </CancelButton>
              <ConfirmButton
                variant="contained"
                onClick={() => confirmActionHandle(modal.action)}
                color={
                  modal.action === EActionConfirm.UNBAN ? 'primary' : 'error'
                }
              >
                {modal.activeButton}
              </ConfirmButton>
            </Box>
          </WarningBox>
        </Modal>
      ) : null}
    </Box>
  );
};
