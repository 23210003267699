import { Components } from '@mui/material';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      minWidth: '230px',
      minHeight: '40px',
      borderRadius: '8px',
    },
  },
  defaultProps: {
    variant: 'outlined',
  },
};
