import { EReportStatusSend } from '@/types';

export type TSortable = 'createdAt' | 'sender' | 'user';

export const sortedFields = {
  createdAt: 'createdAt',
  sender: 'sender.username',
  user: 'user.username',
};

export const StatusName: { [key in EReportStatusSend]: string } = {
  [EReportStatusSend.NEW]: 'Active',
  [EReportStatusSend.RESOLVED]: 'Resolved',
};
