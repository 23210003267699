import { Box, styled, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';


type AppBarProps = MuiAppBarProps & {
  open?: boolean;
  isMobile?: boolean;
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, isMobile }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.neutral?.main,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.border?.main}`,
  ...(open && {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(isMobile && {
      zIndex: 1300,
      height: 60,
    })
  }),
}));

export const AppToolbar = styled(Toolbar)(({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    max-height: 60px;
  }
`
);

export const BrendIcon = styled(Box)`
  width: 46px;
  height: 46px;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
