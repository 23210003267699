import { Helmet } from 'react-helmet';

import { EResetPassword } from '@/types/appProps';

import { SetPasswordForm } from '../../components/SetPasswordForm';

export const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <SetPasswordForm page={EResetPassword.RESET_PASSWORD} />
    </>
  );
};
