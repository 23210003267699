import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const selectUsers = (state: RootState) => state.users;

export const getUsersList = createSelector([selectUsers], ({ users }) => users);

export const getUserFilterList = createSelector(
  [selectUsers],
  ({ filteredUsers }) => filteredUsers
);

export const getUserById = (id: string) =>
  createSelector([selectUsers], ({ users }) =>
    users?.find((user) => user.id === id)
  );

export const getUserTotalCount = createSelector(
  [selectUsers],
  ({ totalCount }) => totalCount
);

export const getUserFilteredCount = createSelector(
  [selectUsers],
  ({ filteredCount }) => filteredCount
);

export const getUserLoader = createSelector(
  [selectUsers],
  ({ contentLoading }) => contentLoading
);
