import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InnerLink } from '@/components/common';
import { ROUTES } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { loginUser } from '@/store/thunks';
import { theme } from '@/theme';
import { UserLogin } from '@/types';

import { loginSchema } from './constants';

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserLogin>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<UserLogin> = useCallback(
    (data) => {
      dispatch(loginUser(data))
        .unwrap()
        .then(() => {
          navigate(ROUTES.DASHBOARD.REPORTS);
        })
        .catch((err) => console.error(err));
    },
    [dispatch, navigate]
  );

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          label="Email"
          autoCapitalize="none"
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          label="Password"
          {...register('password')}
          type={showPassword ? 'text' : 'password'}
          error={!!errors.password}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{ marginTop: 3, marginBottom: '10px' }}
          disabled={!isValid}
        >
          Login
        </Button>
      </Box>
      <Button fullWidth variant="text">
        <InnerLink
          to={ROUTES.AUTH.RECOVER}
          style={{
            color: theme.palette.primary.main,
          }}
        >
          Recovery Password
        </InnerLink>
      </Button>
    </>
  );
};
