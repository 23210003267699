import { Box, styled, Typography } from '@mui/material';

export const BlockWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral?.main};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 48%;
  padding: 16px;
`;

export const BlockTitle = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;
