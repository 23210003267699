// eslint-disable-next-line simple-import-sort/imports
import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { client } from './client';
import { setupInterceptorsTo } from './client/interceptors';
import { ApplyRouter } from './components';
import { GlobalSpinner } from './components/common';
import { STORAGE_ACCESS_TOKEN_KEY } from './constants';
import { useAppDispatch } from './hooks';
import { LoggerService, ToastService } from './services';
import { store } from './store';
import { setLoader } from './store/actions';
import { me } from './store/thunks';

setupInterceptorsTo(client, store);

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    ToastService.onChange((toast) => {
      LoggerService.log(toast.data);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);

    if (token) {
      dispatch(me());
    } else {
      dispatch(setLoader(false));
    }
  }, [dispatch]);

  return (
    <>
      <CssBaseline />

      <GlobalSpinner />

      <ToastContainer />
      <ApplyRouter />
    </>
  );
};

export default App;
