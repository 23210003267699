import { Box, styled } from '@mui/material';

import { sideMenuWidth } from '../constants';

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: isMobile ? 0 : theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobile ? 0 : `-${sideMenuWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  background: theme.palette.neutral?.dark,
  minHeight: `100vh`,
  width: isMobile ? '100%' : 'calc(100vw - 264px)',
}));

export const ContentBox = styled(Box)`
  width: 100%;
`;
