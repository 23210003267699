import { Pagination } from '.';
import { ClientUser } from './client-user';

export enum EReportStatus {
  NEW = 'NEW',
  RESOLVED = 'RESOLVED',
}

export enum EReportStatusSend {
  NEW = 0,
  RESOLVED = 1,
}

export enum EChatType {
  P2P = 'p2p',
  GLOBAL = 'global',
  HASHTAG = 'hashtag',
}

export enum EReason {
  SPAM = 'spam',
  VIOLENCE = 'violence',
  PORNOGRAPHY = 'pornography',
  CHILD = 'child-abuse',
  COPYRIGHT = 'copyright',
  DRUGS = 'illegal-drugs',
  PERSONAL = 'personal-details',
  OTHER = 'other',
}

export const ReportReason: { [key in EReason]: string } = {
  [EReason.CHILD]: 'Child Abuse',
  [EReason.COPYRIGHT]: 'Copyright',
  [EReason.DRUGS]: 'Illegal Drugs',
  [EReason.PERSONAL]: 'Personal Details',
  [EReason.PORNOGRAPHY]: 'Pornography',
  [EReason.SPAM]: 'Spam',
  [EReason.VIOLENCE]: 'Violence',
  [EReason.OTHER]: 'Other',
};

export const ChatType: { [key in EChatType]: string } = {
  [EChatType.GLOBAL]: 'Regional Chat',
  [EChatType.P2P]: 'P2P Chat',
  [EChatType.HASHTAG]: 'Topic Chat',
};

export enum ESortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum EReportObject {
  MESSAGE = 'Message',
  USER = 'User',
}

export enum EReportObjectSend {
  MESSAGE = 'message',
  USER = 'user',
}

export enum ESolution {
  BANNED_FROM_CHAT = 'banned_from_chat',
  BANNED_FROM_APP = 'banned_from_app',
  IGNORED = 'ignored',
  KICKED = 'kicked',
  BLOCKED = 'blocked',
  MESSAGE_DELETED = 'message_deleted',
}

export const ReportSolution: { [key in ESolution]: string } = {
  [ESolution.BANNED_FROM_APP]: 'Banned From App',
  [ESolution.BANNED_FROM_CHAT]: 'Banned From Chat',
  [ESolution.BLOCKED]: 'Blocked',
  [ESolution.IGNORED]: 'Ignored',
  [ESolution.KICKED]: 'Kicked',
  [ESolution.MESSAGE_DELETED]: 'Message Deleted',
};

export type ReportContext = {
  messageText?: string | null;
  messageImage?: string | null;
  messageId: string;
};

export type TargetChat = {
  chatName?: string;
  guid?: string;
};

export type Report = {
  id: string;
  senderId?: string;
  sender?: ClientUser;
  userId?: string;
  user?: ClientUser;
  status: EReportStatusSend;
  solution?: ESolution;
  target: EChatType;
  targetId?: string;
  targetChat?: TargetChat;
  reason: EReason;
  description?: string;
  context?: ReportContext;
  createdAt?: string;
  updatedAt?: string;
  archivedAt?: string;
  deletedAt?: string;
};

export type PaginatedReports = {
  data: Report[];
  meta: Pagination;
};

export type ReportParams = {
  page?: number;
  limit: number;
  offset: number;
  status?: EReportStatus;
  onlyArchived?: boolean;
  orderBy?: string;
  orderDirection?: ESortDirection;
  search?: string;
  type?: EReportObjectSend;
  target?: EChatType;
  reason?: EReason | string;
  senderId?: string;
  userId?: string;
  messageId?: string;
};

export type BanUserParams = {
  id: string;
  duration?: null | number;
};

export interface ReportSaveParams extends ReportParams {
  withoutSave?: boolean;
}
