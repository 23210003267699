import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const selectReports = (state: RootState) => state.report;

export const getReportsList = createSelector(
  [selectReports],
  ({ reports }) => reports
);

export const getReportFilterList = createSelector(
  [selectReports],
  ({ filteredReports }) => filteredReports
);

export const getReportById = (id: string) =>
  createSelector([selectReports], ({ reports }) =>
    reports?.find((report) => report.id === id)
  );

export const getTotalCount = createSelector(
  [selectReports],
  ({ totalCount }) => totalCount
);

export const getReportFilteredCount = createSelector(
  [selectReports],
  ({ filteredCount }) => filteredCount
);

export const getNewCount = createSelector(
  [selectReports],
  ({ newCount }) => newCount
);

export const getReportLoading = createSelector(
  [selectReports],
  ({ reportLoading }) => reportLoading
);
