import { Button, styled, Typography } from '@mui/material';

export const ConfirmTitle = styled(Typography)`
  margin-top: 30px;
  margin-bottom: 14px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
`;

export const ConfirmText = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;
`;

export const ConfirmButton = styled(Button)`
  width: 48%;
  min-width: unset;
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: ${({ theme }) => theme.palette.grey[400]};
  color: ${({ theme }) => theme.palette.grey[700]};
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;
