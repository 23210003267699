import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const InnerLink: FC<LinkProps> = (props) => {
  return (
    <Link
      {...props}
      style={{
        ...props.style,
        textDecoration: 'none',
      }}
    >
      {props.children}
    </Link>
  );
};
