import { ESortDirection, Pagination } from '.';

export enum EClientUserStatus {
  ACTIVE = 'active',
  BAN = 'banned_from_app',
}

export type ClientUser = {
  id: string;
  activeBans: number;
  email: string;
  username: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
  description: string;
  avatarUrl: string;
  bannerUrl: string;
  onlineStatus: number;
  lastOnline: string;
  createdAt?: string;
  isBlockedInTheSystem?: boolean;
  status: EClientUserStatus;
};

export type PaginatedUsers = {
  data: ClientUser[];
  meta: Pagination;
};

export type UserParams = {
  page?: number;
  limit: number;
  offset: number;
  orderBy?: string;
  orderDirection?: ESortDirection;
  search?: string;
  status?: EClientUserStatus;
};
