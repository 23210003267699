import { PaletteOptions as PaletteOpts, SimplePaletteColorOptions } from '@mui/material';


type CustomColors = {
  avatar: string;
  selected: string;
};

declare module '@mui/material/styles' {
  interface Palette {
    custom: CustomColors;
    neutral?: SimplePaletteColorOptions;
    border?: SimplePaletteColorOptions;
  }
  interface Theme {
    custom: CustomColors;
  }
  interface PaletteOptions {
    custom: CustomColors;
    neutral?: SimplePaletteColorOptions;
    border?: SimplePaletteColorOptions;
  }
}

export const palette: PaletteOpts = {
  custom: {
    avatar: '#DBE6CE',
    selected: '#2196F314',
  },
  neutral: {
    light: '#D3E7E1',
    main: '#FFFFFF',
    dark: '#EDEDED;',
  },
  text: {
    primary: '#000000',
    secondary: '#000000de',
  },
  border: {
    main: '#0000001f',
    light: '#0000008A',
  },
  primary: {
    main: '#2196F3',
  },
  success: {
    main: '#2E7D32',
    dark: '#01579B',
  },
  info: {
    main: '#29b6f6', // mui default
    dark: '#0288d1',
  },
  warning: {
    main: '#ffa726', // mui default
    dark: '#ED6C02',
  },
};