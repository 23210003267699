import {
  EClientUserStatus,
  EReportTabType,
  ESolution,
  EUserRole,
  EUserStatus,
} from '@/types';

import { theme } from '..';

export const StatusColor: { [key in EUserStatus]: string } = {
  [EUserStatus.ACTIVE]: theme.palette.success.dark,
  [EUserStatus.PENDING]: theme.palette.info.dark,
  [EUserStatus.SUSPEND]: theme.palette.warning.dark,
  [EUserStatus.NEW]: theme.palette.info.dark,
};

export const RoleColor: { [key in EUserRole]: string } = {
  [EUserRole.ADMIN]: theme.palette.primary.dark,
  [EUserRole.MODERATOR]: theme.palette.secondary.dark,
};

export const ReportStatusColor: { [key in EReportTabType]?: string } = {
  [EReportTabType.ACTIVE]: theme.palette.success.main,
  [EReportTabType.RESOLVED]: theme.palette.primary.main,
  [EReportTabType.ARCHIVED]: theme.palette.warning.dark,
};

export const UserStatusColor: { [key in EClientUserStatus]?: string } = {
  [EClientUserStatus.ACTIVE]: theme.palette.success.main,
  [EClientUserStatus.BAN]: theme.palette.error.dark,
};

export const SolutionColor: { [key in ESolution]?: string } = {
  [ESolution.BANNED_FROM_APP]: theme.palette.error.dark,
  [ESolution.KICKED]: theme.palette.warning.main,
  [ESolution.BANNED_FROM_CHAT]: theme.palette.error.main,
  [ESolution.MESSAGE_DELETED]: theme.palette.info.dark,
  [ESolution.IGNORED]: theme.palette.info.main,
  [ESolution.BLOCKED]: theme.palette.warning.main,
};
