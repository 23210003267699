import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { acceptInvite, resetPassword } from '@/store/thunks';
import { UserSetPassword } from '@/types';
import { EResetPassword } from '@/types/appProps';

import { resetPasswordSchema } from '../constants';

type SetPasswordFormProps = {
  page: EResetPassword;
};

export const SetPasswordForm: FC<SetPasswordFormProps> = ({ page }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const handleClickShowPasswordRepeat = useCallback(() => {
    setShowRepeatPassword((show) => !show);
  }, []);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserSetPassword>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<UserSetPassword> = useCallback(
    (data) => {
      if (token) {
        if (page === EResetPassword.RESET_PASSWORD) {
          dispatch(
            resetPassword({
              newPassword: data.password,
              resetToken: token,
            })
          )
            .unwrap()
            .then(() => {
              navigate(ROUTES.AUTH.LOGIN);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          dispatch(
            acceptInvite({
              newPassword: data.password,
              resetToken: token,
            })
          )
            .unwrap()
            .then(() => {
              navigate(ROUTES.AUTH.LOGIN);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
    [dispatch, navigate, page, token]
  );

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        variant="standard"
        sx={{ marginBottom: 2 }}
        label="Password"
        {...register('password')}
        type={showPassword ? 'text' : 'password'}
        error={!!errors.password}
        helperText={errors.password?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        variant="standard"
        sx={{ marginBottom: 2 }}
        label="Confirm Password"
        {...register('repeatPassword')}
        type={showRepeatPassword ? 'text' : 'password'}
        error={!!errors.repeatPassword}
        helperText={errors.repeatPassword?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle repeat password visibility"
                onClick={handleClickShowPasswordRepeat}
                onMouseDown={handleMouseDownPassword}
              >
                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        variant="contained"
        fullWidth
        type="submit"
        sx={{ marginTop: 3, marginBottom: '10px' }}
        disabled={!isValid}
      >
        {page === EResetPassword.INVITATION
          ? 'Create Account'
          : 'Reset Password'}
      </Button>
    </Box>
  );
};
