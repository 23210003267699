import { Box, styled } from "@mui/material";


export const ImageWrapper = styled(Box)`
  padding: 30px;
  width: 80vw;
  height: 80vh;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 16px;
    width: 90vw;
    height: 95vh;
  }
`;

export const FullImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;
