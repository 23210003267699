import { mixed, object, string } from 'yup';

import { FIELD_IS_REQUIRED, INVALID_EMAIL } from '@/constants';
import { EUserRole } from '@/types';

const MAX_FIRST_NAME = 'Name cannot exceed 50 characters';
const MAX_LAST_NAME = 'Last name cannot exceed 50 characters';
const MAX_EMAIL = 'Email cannot exceed 255 characters';

export const adminSchema = object({
  email: string()
    .required(FIELD_IS_REQUIRED)
    .max(255, MAX_EMAIL)
    .email(INVALID_EMAIL),
  firstName: string().required(FIELD_IS_REQUIRED).max(50, MAX_FIRST_NAME),
  lastName: string().required(FIELD_IS_REQUIRED).max(50, MAX_LAST_NAME),
  role: mixed<EUserRole | string>()
    .required(FIELD_IS_REQUIRED)
    .oneOf([EUserRole.ADMIN, EUserRole.MODERATOR]),
});
