import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from './../constants/auth';

export const getAccessToken = () =>
  localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
export const getRefreshToken = () =>
  localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY);

export const setAccessToken = (token: string) =>
  localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, token);
export const setRefreshToken = (token: string) =>
  localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, token);

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const removeAuthTokens = () => {
  localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
  localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY);
};
