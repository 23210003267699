import { useMediaQuery } from '@mui/material';
import { GridColumnMenuProps, gridFilterModelSelector, GridFilterPanel, GridLogicOperator, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';

import { theme } from '@/theme';

export const GridFilterCustom = (props: GridColumnMenuProps) => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const setMyfilterModel = () => {
    apiRef.current.setFilterModel({
      items: [
        {
          field: props.colDef.field,
          operator: 'is',
        },
      ],
      logicOperator: GridLogicOperator.And,
      quickFilterLogicOperator: GridLogicOperator.And,
      quickFilterValues: [],
    });
  };

  useEffect(() => {
    if (
      filterModel?.items?.length &&
      filterModel.items[0].field !== props.colDef.field
    ) {
      setMyfilterModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.colDef.field, filterModel]);

  const handleColumnForNewFilter = useCallback(() => {
    return props.colDef.field;
  }, [props.colDef.field]);

  return (
    <GridFilterPanel
      getColumnForNewFilter={() => handleColumnForNewFilter()}
      sx={{
        width : isSmallScreen ? '100%' : 'inherit',
        '.MuiDataGrid-filterFormOperatorInput': {
          display: 'none',
        }
      }}
    />
  );
};
