import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '../constants';
import { NotFound } from './404';
import { AuthLayout, Invitation, Login } from './Auth';
import { ResetPassword, ResetPasswordNotification } from './Auth/pages';
import { RecoveryPassword } from './Auth/pages/Recover';
import { DashboardLayout } from './Dashboard';
import { Admins } from './Dashboard/pages/Admins';
import { ReportDetail } from './Dashboard/pages/ReportDetail';
import { Reports } from './Dashboard/pages/Reports';
import { Settings } from './Dashboard/pages/Settings/Settings';
import { UserDetail } from './Dashboard/pages/UserDetail';
import { Users } from './Dashboard/pages/Users';

export const routes: RouteObject[] = [
  {
    path: ROUTES.ROOT,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.AUTH.LOGIN} />,
      },
      {
        path: ROUTES.AUTH.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.AUTH.INVITATION,
        element: <Invitation />,
      },
      {
        path: ROUTES.AUTH.RECOVER,
        element: <RecoveryPassword />,
      },
      {
        path: ROUTES.AUTH.RESET_NOTIFICATION,
        element: <ResetPasswordNotification />,
      },
      {
        path: ROUTES.AUTH.RESET_PASSWORD,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: ROUTES.ROOT,
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Reports />,
      },
      {
        path: ROUTES.DASHBOARD.ADMINS,
        element: <Admins />,
      },
      {
        path: ROUTES.DASHBOARD.REPORTS,
        element: <Reports />,
      },
      {
        path: ROUTES.DASHBOARD.REPORT_DETAIL,
        element: <ReportDetail />,
      },
      {
        path: ROUTES.DASHBOARD.USERS,
        element: <Users />,
      },
      {
        path: ROUTES.DASHBOARD.USER_DETAIL,
        element: <UserDetail />,
      },
      {
        path: ROUTES.DASHBOARD.SETTINGS,
        element: <Settings />,
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];
