import { createSlice } from '@reduxjs/toolkit';

import { BanReport } from '@/types';
import { BanSlice } from '@/types/slices/bans';

import { getBans, getFilteredBans, getMoreBans } from '../thunks/bans';

const initialState: BanSlice = {
  bans: null,
  filteredBans: null,
  totalCount: null,
  filteredCount: null,
  banLoading: false,
};

export const bansSlice = createSlice({
  name: 'bansSlice',
  initialState,
  reducers: {
    clearBans: (state) => {
      state.bans = null;
      state.filteredBans = null;
      state.totalCount = null;
    },
    setBanLoading: (state, { payload }) => {
      state.banLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBans.pending, (state) => {
        state.bans = [];
      })
      .addCase(getBans.fulfilled, (state, { payload }) => {
        if (payload) {
          const myBans = payload.data;
          state.bans = myBans;
          state.totalCount = payload.meta.totalCount;
        }
      })
      .addCase(getMoreBans.fulfilled, (state, { payload }) => {
        if (payload) {
          const myBans = payload.data as BanReport[];
          state.bans = state.bans ? [...state.bans, ...myBans] : myBans;
        }
      })
      .addCase(getFilteredBans.fulfilled, (state, { payload }) => {
        if (payload) {
          const myBans = payload.data as BanReport[];
          state.filteredBans = state.filteredBans
            ? [...state.filteredBans, ...myBans]
            : myBans;
          state.filteredCount = payload.meta.totalCount;
        }
      });
  },
});
