import { configureStore } from '@reduxjs/toolkit';

import {
  adminsSlice,
  appSlice,
  authSlice,
  bansSlice,
  reportsSlice,
  userSlice,
} from './slices';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    app: appSlice.reducer,
    admins: adminsSlice.reducer,
    report: reportsSlice.reducer,
    users: userSlice.reducer,
    bans: bansSlice.reducer,
  },
});
