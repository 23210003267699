import { Box, Button } from '@mui/material';
import { Helmet } from 'react-helmet';

import { InnerLink } from '@/components/common';
import { ROUTES } from '@/constants';
import { theme } from '@/theme';

import NotificationSend from '../../../../assets/pictures/rafiki.png';

export const ResetPasswordNotification = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <img src={NotificationSend} />
      <Box textAlign="center">
        <p>
          Recovery link has been sent!
          <br />
          Please check your Inbox for the password reset link.
        </p>
        <p>
          If the email doesn&apos;t arrive within a few minutes, please check
          your Spam and Trash folders
        </p>
      </Box>
      <Button fullWidth variant="contained">
        <InnerLink
          to={ROUTES.AUTH.LOGIN}
          style={{
            color: theme.palette.neutral?.main,
          }}
        >
          Back to login
        </InnerLink>
      </Button>
    </Box>
  );
};
