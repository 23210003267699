import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const selectUser = (state: RootState) => state.auth;

export const isAuthorized = createSelector([selectUser], ({ user }) => !!user);

export const getUserName = createSelector([selectUser], ({ user }) =>
  user
    ? `${
        user.firstName
          ? user.firstName + ' ' + (user.lastName || '')
          : user.email
      }`
    : null
);

export const getUserLoaded = createSelector(
  [selectUser],
  ({ loading }) => !loading
);

export const getAdminRole = createSelector(
  [selectUser],
  ({ user }) => user?.role
);

export const getMe = createSelector([selectUser], ({ user }) => user);
