import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch } from '@/hooks';
import { changeMyPassword } from '@/store/thunks';
import { AdminUserPassword } from '@/types';

import { changePasswordSchema } from './constants/changePasswordSchema';
import { BlockTitle, BlockWrapper } from './styled/components';

export const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<AdminUserPassword>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const handleClickShowPasswordRepeat = useCallback(() => {
    setShowRepeatPassword((show) => !show);
  }, []);

  const handleClickShowPasswordOld = useCallback(() => {
    setShowOldPassword((show) => !show);
  }, []);

  const onSubmitName: SubmitHandler<AdminUserPassword> = useCallback(
    (data) => {
      dispatch(changeMyPassword(data))
        .unwrap()
        .then(() => {
          reset();
        });
    },
    [dispatch, reset]
  );

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  return (
    <BlockWrapper component="form" onSubmit={handleSubmit(onSubmitName)}>
      <BlockTitle>Change Password</BlockTitle>
      <TextField
        fullWidth
        variant="standard"
        sx={{ marginBottom: 2 }}
        label="Current Password"
        {...register('oldPassword')}
        type={showOldPassword ? 'text' : 'password'}
        error={!!errors.oldPassword}
        helperText={errors.oldPassword?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle current password visibility"
                onClick={handleClickShowPasswordOld}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        variant="standard"
        sx={{ marginBottom: 2 }}
        label="New Password"
        {...register('password')}
        type={showPassword ? 'text' : 'password'}
        error={!!errors.password}
        helperText={errors.password?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        variant="standard"
        sx={{ marginBottom: 2 }}
        label="Confirm Password"
        {...register('confirmPassword')}
        type={showRepeatPassword ? 'text' : 'password'}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordRepeat}
                onMouseDown={handleMouseDownPassword}
              >
                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button type="submit" variant="contained" disabled={!isValid} fullWidth>
        Change Password
      </Button>
    </BlockWrapper>
  );
};
