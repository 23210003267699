import { Close } from "@mui/icons-material";
import { Backdrop, Box, IconButton } from "@mui/material";
import { FC } from "react";

import { FullImage, ImageWrapper } from "./styled";

type ImageViewProps = {
  isOpen: boolean;
  onClose: () => void;
  picture: string;
}

export const ImageView: FC<ImageViewProps> = ({
  isOpen,
  onClose,
  picture
}) => {

  return (
    <Backdrop
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: .8 }}
      open={isOpen}
      onClick={onClose}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 18,
            top: 18,
            color: (theme) => theme.palette.neutral?.main,
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <ImageWrapper>
        <FullImage src={picture} />
      </ImageWrapper>
    </Backdrop>
  )
}
