import { CircularProgress, Modal } from '@mui/material';

import { useAppSelector } from '@/hooks';
import { getShowGlobalSpinner } from '@/store/selectors';
import { theme } from '@/theme';

export const GlobalSpinner = () => {
  const showSpinner = useAppSelector(getShowGlobalSpinner);

  return (
    <Modal
      open={showSpinner}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.primary,
        '& .MuiCircularProgress-indeterminate:focus-visible': {
          outline: 'none',
        },
      }}
    >
      <CircularProgress />
    </Modal>
  );
};
