export const banTime = [
  {
    value: 30,
    label: '30 Min',
  },
  {
    value: 60,
    label: '1 Hour',
  },
  {
    value: 1440,
    label: '1 Day',
  },
  {
    value: 21600,
    label: '15 Days',
  },
];
