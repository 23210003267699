import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  display: flex;
  height: 90vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
