import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';

import { ModalContent } from './styled';

type ModalProps = {
  children: ReactNode;
  title?: string;
  isOpen: boolean;
  hasCloseIcon?: boolean;
  actions?: ReactNode;
  onClose: () => void;
};

export const Modal: FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  hasCloseIcon = true,
  children,
  actions,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        {hasCloseIcon ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <ModalContent>{children}</ModalContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};
