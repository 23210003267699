export enum EAuth {
  LOGIN = 'auth/loginUser',
  ME = 'auth/me',
  LOGOUT = 'auth/logout',
  FORGOT_PASSWORD = 'auth/forgotPassword',
  RESET_PASSWORD = 'auth/resetPassword',
  ACCEPT_INVITE = 'auth/acceptInvite',
  CHANGE_NAME = 'auth/changeName',
  CHANGW_PASSWORD = 'auth/changePassword',
}
