export const ROUTES = {
  AUTH: {
    LOGIN: '/login',
    RECOVER: '/recover',
    RESET_NOTIFICATION: '/reset-notification',
    INVITATION: '/invitation',
    RESET_PASSWORD: '/reset-password',
  },
  DASHBOARD: {
    REPORTS: '/reports',
    REPORT_DETAIL: '/reports/:id',
    USERS: '/users',
    USER_DETAIL: '/users/:id',
    SETTINGS: '/settings',
    ADMINS: '/admins',
  },
  ROOT: '/',
  NOT_FOUND: '*',
  BACK: -1,
} as const;
