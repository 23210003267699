import { Box, Tab } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { EReportTabType } from '@/types/appProps';

import {
  TablePreHeader,
  TableWrapper,
  TitlePreHeader,
} from '../../styled/TableWrapper';
import { ReportTable } from './ReportTable';
import { TabsHeader } from './styled/ReportTabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const Reports = () => {
  const [shouldClearReports, setShouldClearReports] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<EReportTabType>(
    EReportTabType.ACTIVE
  );
  const [nextTab, setNextTab] = useState<EReportTabType>(currentTab);

  useEffect(() => {
    if (shouldClearReports) {
      setTimeout(() => {
        setCurrentTab(nextTab);
      }, 500);
    }
  }, [shouldClearReports, nextTab]);

  const changeTab = useCallback(
    (_: React.SyntheticEvent, newValue: EReportTabType) => {
      setShouldClearReports(true);
      setNextTab(newValue);
    },
    []
  );

  return (
    <TableWrapper>
      <Helmet>
        <title>Reporting</title>
      </Helmet>
      <TablePreHeader width="100%">
        <TitlePreHeader>Reporting</TitlePreHeader>
        <TabsHeader value={currentTab} onChange={changeTab}>
          <Tab label="Active Reports" value={EReportTabType.ACTIVE} />
          <Tab label="Resolved Reports" value={EReportTabType.RESOLVED} />
          <Tab label="Archived Reports" value={EReportTabType.ARCHIVED} />
        </TabsHeader>
      </TablePreHeader>

      <TabPanel value={currentTab} index={EReportTabType.ACTIVE}>
        <ReportTable
          shouldClearReports={shouldClearReports}
          type={EReportTabType.ACTIVE}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={EReportTabType.RESOLVED}>
        <ReportTable
          shouldClearReports={shouldClearReports}
          type={EReportTabType.RESOLVED}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={EReportTabType.ARCHIVED}>
        <ReportTable
          shouldClearReports={shouldClearReports}
          type={EReportTabType.ARCHIVED}
        />
      </TabPanel>
    </TableWrapper>
  );
};
