export enum EResetPassword {
  INVITATION = 'invitation',
  RESET_PASSWORD = 'reset-password',
}

export enum EReportTabType {
  ACTIVE = 'active',
  RESOLVED = 'resolved',
  ARCHIVED = 'archived',
}

export type PaginationInfo = {
  page: number;
  limit: number;
  offset: number;
};

export type Pagination = {
  nextPage: number | null;
  page: number | null;
  perPage: number | null;
  prevPage: number | null;
  totalCount: number;
  totalPages: number;
};
