import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useAppSelector } from '@/hooks';
import { isAuthorized } from '@/store/selectors';

export const AuthUserGuard: FC = () => {
  const isAuthenticated = useAppSelector(isAuthorized);

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }
};
