import { CheckCircle, Search } from '@mui/icons-material';
import { Box, capitalize, InputAdornment, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { format, formatISO, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextMenuReport } from '@/components/common/ContextMenuReport';
import { CustomFilterIcon, GridFilterCustom } from '@/components/common/GridFilterCustom';
import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';
import { StatusChip } from '@/components/styled/Chip';
import { ROUTES } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import { getFilteredReports, getMoreReports, getReports } from '@/store/thunks';
import { theme } from '@/theme';
import { ReportStatusColor } from '@/theme/theming/statusesTheme';
import { ChatType, EChatType, EReason, EReportObject, EReportObjectSend, EReportStatus, EReportStatusSend, EReportTabType, ESortDirection, PaginatedReports, Report, Report as TReport,ReportReason } from '@/types';

import NoReport from '../../../../assets/pictures/ReportOff.png';
import NoSearch from '../../../../assets/pictures/Search.svg';
import { SearchInput, TableContent, TablePreHeader, TableWrapper, TitlePreHeader } from '../../styled/TableWrapper';
import { sortedFields, StatusName, TSortable } from './constants/sortModels';

interface INoResultOverlay {
  historyType: EReportObject;
}

// eslint-disable-next-line react/no-multi-comp
const NoFilterResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <img src={NoSearch} />
      <EmptyReport>No report found</EmptyReport>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const NoResult = ({ historyType }: INoResultOverlay) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <img src={NoReport} />
      <EmptyReport>
        There are no reports for this{' '}
        {historyType === EReportObject.MESSAGE ? 'message' : 'user'}
      </EmptyReport>
    </Box>
  );
};

const rowsMapper = (reports: Array<TReport>) =>
  reports.map((report) => ({
    createdAt: report.createdAt,
    reportObject: report.context?.messageId
      ? EReportObject.MESSAGE
      : EReportObject.USER,
    target: report.target,
    targetName: report.targetChat?.chatName || '-',
    id: report.id,
    user: report.user || null,
    sender: report.sender || null,
    reason: report.reason,
    message: report.context?.messageText || '',
    status: report.status,
    archivedAt: report.archivedAt,
  }));

interface ReportHistoryProps {
  historyType: EReportObject;
  report?: Report;
  mainId: string;
}

// eslint-disable-next-line react/no-multi-comp
export const ReportHistory: FC<ReportHistoryProps> = ({
  report,
  historyType,
  mainId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filtering, setFiltering] = useState<GridFilterModel | null>(null);
  const [searchParams, setSearchParams] = useState<string>('');

  const historyTypeParams = useMemo(() => {
    if (historyType === EReportObject.USER) {
      return {
        userId: mainId,
        withoutSave: true,
      };
    } else {
      return {
        messageId: mainId,
        withoutSave: true,
      };
    }
  }, [historyType, mainId]);

  const sortParams = useMemo(() => {
    if (sorting?.length && sorting[0].sort) {
      return {
        orderBy: sortedFields[sorting[0].field as TSortable],
        orderDirection: sorting[0].sort.toUpperCase() as ESortDirection,
      };
    }
    return {};
  }, [sorting]);

  const createFilterObject = (
    filter: GridFilterModel | null,
    search?: string
  ) => {
    if (search) {
      return {
        search,
      };
    } else if (filter?.items[0].field === 'target') {
      return {
        target: filter?.items[0].value,
      };
    } else if (filter?.items[0].field === 'reason') {
      return {
        reason: filter?.items[0].value,
      };
    } else if (
      filter?.items[0].field === 'reportObject' &&
      filter?.items[0].value
    ) {
      return {
        type: (
          filter?.items[0].value as string
        ).toLowerCase() as EReportObjectSend,
      };
    }
    return {};
  };

  const filterParams = useMemo(() => {
    if (filtering?.items.length) {
      return createFilterObject(filtering, searchParams);
    }
    return {};
  }, [filtering, searchParams]);

  useEffect(() => {
    dispatch(
      getReports({
        limit: 10,
        offset: 0,
        ...historyTypeParams,
      })
    )
      .unwrap()
      .then((reportList) => {
        if (reportList) {
          setReports(
            report
              ? reportList?.data.filter(({ id }) => report.id !== id)
              : reportList?.data
          );
          setTotalCount(
            report
              ? reportList?.meta.totalCount - 1
              : reportList?.meta.totalCount
          );
        }
      })
      .catch((err) => {
        ToastService.error(err.message);
      });
  }, [dispatch, historyTypeParams, report]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSorting(sortModel);
      const sorts =
        sortModel?.length && sortModel[0].sort
          ? {
              orderBy: sortedFields[sortModel[0].field as TSortable],
              orderDirection: sortModel[0].sort.toUpperCase() as ESortDirection,
            }
          : {};
      dispatch(
        getReports({
          offset: 0,
          limit: paginationModel.pageSize,
          ...sorts,
          ...historyTypeParams,
          ...filterParams,
        })
      )
        .unwrap()
        .then((reportList) => {
          if (reportList) {
            setReports(
              report
                ? reportList?.data.filter(({ id }) => report.id !== id)
                : reportList?.data
            );
          }
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, filterParams, historyTypeParams, paginationModel.pageSize, report]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFiltering(filterModel);
      const filter = filterModel?.items.length
        ? createFilterObject(filterModel)
        : {};

      dispatch(
        getFilteredReports({
          offset: 0,
          limit: paginationModel.pageSize,
          ...sortParams,
          ...historyTypeParams,
          ...filter,
        })
      )
        .unwrap()
        .then((filteredReport: PaginatedReports | null) => {
          if (filteredReport) {
            setReports(
              report
                ? filteredReport.data.filter(({ id }) => report.id !== id)
                : filteredReport.data
            );
            setTotalCount(() => {
              const totalAmount = filteredReport.meta.totalCount
                ? filteredReport.meta.totalCount
                : paginationModel.pageSize > filteredReport.data.length ?
                filteredReport.data.length :
                filteredReport.meta.totalPages * (filteredReport.meta.perPage || paginationModel.pageSize);
              return report && filteredReport.data.some(({ id }) => id === report.id)
                ? totalAmount - 1
                : totalAmount;
            });
          }
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    },
    [dispatch, historyTypeParams, paginationModel.pageSize, report, sortParams]
  );

  const handlePaginationModel = useCallback(
    (pagination: GridPaginationModel) => {
      setPaginationModel(pagination);
      if (
        reports?.length &&
        (pagination.page + 1) * pagination.pageSize > reports?.length
      ) {
        dispatch(
          getMoreReports({
            offset: reports.length,
            limit: pagination.pageSize,
            ...historyTypeParams,
            ...sortParams,
            ...filterParams,
          })
        )
          .unwrap()
          .then((resultList) => {
            if (resultList) {
              const reportList = resultList.data.filter(
                ({ id }) => report?.id !== id
              );
              setReports((prevRep) =>
                prevRep?.length ? [...prevRep, ...reportList] : reportList
              );
            }
          })
          .catch((err) => {
            ToastService.error(err.message);
          });
      }
    },
    [
      reports?.length,
      dispatch,
      historyTypeParams,
      sortParams,
      filterParams,
      report?.id,
    ]
  );

  const search = useCallback(
    (searchStr: string) => {
      if (reports) {
        setSearchParams(searchStr);
        if (!searchStr) {
          dispatch(
            getReports({
              offset: 0,
              limit: 10,
              ...sortParams,
              ...historyTypeParams,
            }) || ''
          )
            .unwrap()
            .then((reportList) => {
              if (reportList) {
                setReports(
                  report
                    ? reportList.data.filter(({ id }) => id !== report.id)
                    : reportList.data
                );
                setTotalCount(
                  report && reportList.data.some(({ id }) => id === report.id)
                    ? reportList.meta.totalCount - 1
                    : reportList.meta.totalCount
                );
              }
            })
            .catch((err) => {
              ToastService.error(err.message);
            });
        } else {
          dispatch(
            getFilteredReports({
              limit: 10,
              offset: 0,
              search: searchStr,
              ...historyTypeParams,
              ...sortParams,
            })
          )
            .unwrap()
            .then((filteredReport) => {
              if (filteredReport) {
                setReports(
                  report
                    ? filteredReport.data.filter(({ id }) => report.id !== id)
                    : filteredReport.data
                );
                setTotalCount(
                  report &&
                    filteredReport.data.some(({ id }) => id === report.id)
                    ? filteredReport.meta.totalCount - 1
                    : filteredReport.meta.totalCount
                );
              }
            })
            .catch((err) => {
              ToastService.error(err.message);
            });
        }
      }
    },
    [dispatch, historyTypeParams, report, reports, sortParams]
  );

  const resolvedIgnore = useCallback((id: string) => {
    setReports(
      (reps) =>
        reps?.map((item) =>
          item.id !== id
            ? item
            : { ...item, status: EReportStatusSend.RESOLVED }
        ) || null
    );
  }, []);

  const archivedRep = useCallback((id: string) => {
    setReports(
      (reps) =>
        reps?.map((item) =>
          item.id !== id ? item : { ...item, archivedAt: formatISO(new Date()) }
        ) || null
    );
  }, []);

  const deleteRep = useCallback(
    (id: string) => {
      if (id === report?.id) {
        ToastService.success('Report successfuly deleted');
        navigate(ROUTES.DASHBOARD.REPORTS);
      } else {
        setReports(
          (reps) =>
            reps?.map((item) =>
              item.id !== id
                ? item
                : { ...item, archivedAt: formatISO(new Date()) }
            ) || null
        );
        setTotalCount((count) => count - 1);
      }
    },
    [navigate, report?.id]
  );

  const restoreRep = useCallback((id: string) => {
    setReports(
      (reps) =>
        reps?.map((item) =>
          item.id !== id ? item : { ...item, archivedAt: undefined }
        ) || null
    );
  }, []);

  const pageData = useMemo(() => {
    return reports?.slice(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
    );
  }, [reports, paginationModel]);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      valueFormatter: (date) => {
        return format(parseISO(date.value), 'dd/MM/yy');
      },
      flex: 1,
      minWidth: 125,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: 'reportObject',
      headerName: 'Report Object',
      flex: 1,
      minWidth: 125,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReportObject),
    },
    {
      field: 'target',
      headerName: 'Chat Type',
      valueFormatter: ({ value }) => {
        return ChatType[value as EChatType];
      },
      flex: 1,
      minWidth: 125,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        {
          value: EChatType.GLOBAL,
          label: ChatType[EChatType.GLOBAL],
        },
        {
          value: EChatType.HASHTAG,
          label: ChatType[EChatType.HASHTAG],
        },
        {
          value: EChatType.P2P,
          label: ChatType[EChatType.P2P],
        },
      ],
    },
    {
      field: 'targetName',
      headerName: 'Chat Name',
      flex: 1,
      minWidth: 125,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'sender',
      headerName: 'Reported by',
      flex: 1,
      minWidth: 125,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Typography
            fontStyle={row?.sender?.username ? 'normal' : 'italic'}
            sx={{
              opacity: row?.sender?.username ? 1 : 0.5,
            }}
          >
            {row?.sender?.username ? row?.sender.username : 'Deleted user'}
          </Typography>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Report ID',
      flex: 1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'reason',
      headerName: 'Reason',
      valueFormatter: ({ value }) => {
        return capitalize((value as string).replace('-', ' '));
      },
      flex: 1,
      minWidth: 125,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: Object.values(EReason).map((value) => ({
        value,
        label: ReportReason[value],
      })),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 125,
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        {
          value: EReportStatus.NEW,
          label: StatusName[EReportStatusSend.NEW],
        },
        {
          value: EReportStatus.RESOLVED,
          label: StatusName[EReportStatusSend.RESOLVED],
        },
        {
          value: -1,
          label: 'Archived',
        },
      ],
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor:
                ReportStatusColor[
                  params.row?.archivedAt
                    ? EReportTabType.ARCHIVED
                    : params.row?.status === EReportStatusSend.NEW
                    ? EReportTabType.ACTIVE
                    : EReportTabType.RESOLVED
                ],
              '.MuiChip-icon': {
                color: theme.palette.neutral?.main,
              },
            }}
            label={
              params.row.archivedAt
                ? 'Archived'
                : StatusName[params.row.status as EReportStatusSend]
            }
            icon={
              !params.row.archivedAt &&
              params.row?.status === EReportTabType.RESOLVED ? (
                <CheckCircle />
              ) : undefined
            }
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 52,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <ContextMenuReport
            params={params}
            onIgnore={(id) => resolvedIgnore(id)}
            onArchive={(id) => archivedRep(id)}
            onDelete={(id) => deleteRep(id)}
            onRestore={(id) => restoreRep(id)}
          />
        );
      },
    },
  ];

  return (
    <TableWrapper>
      <TablePreHeader
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TitlePreHeader>
          {historyType === EReportObject.USER
            ? 'User Reports History'
            : 'Message Reports History'}
        </TitlePreHeader>
      </TablePreHeader>
      <TableContent>
        {reports?.length || searchParams ? (
          <Box>
            <SearchInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              id="search-admin"
              size="small"
              placeholder="Search..."
              variant="standard"
              type="search"
              onChange={(e) => search(e.target.value)}
            />
          </Box>
        ) : null}

        <div style={{ width: '100%', height: reports?.length ? 'unset' : 250 }}>
          <DataGrid
            rows={rowsMapper(pageData || [])}
            columns={columns}
            slots={{
              noResultsOverlay: NoFilterResult,
              noRowsOverlay: Object.keys(filterParams).length
                ? NoFilterResult
                : () => NoResult({ historyType }),
              columnMenu: GridFilterCustom,
              columnMenuIcon: CustomFilterIcon,
            }}
            rowCount={totalCount || 0}
            pageSizeOptions={[10, 25, 50]}
            onRowClick={({ row }) =>
              navigate(`${ROUTES.DASHBOARD.REPORTS}/${row.id}`)
            }
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            sortModel={sorting}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            columnVisibilityModel={
              historyType === EReportObject.USER
                ? {}
                : {
                    reportObject: false,
                    target: false,
                    targetName: false,
                  }
            }
            sx={{
              '.MuiDataGrid-filterIcon': {
                display: 'none',
              },
              [`.MuiDataGrid-columnHeader[data-field=${
                filtering?.items.length && filtering?.items[0].value
                  ? filtering?.items[0].field
                  : ''
              }]`]: {
                '.MuiDataGrid-menuIcon': {
                  display: 'flex',
                  visibility: 'unset',
                  marginRight: '0',
                  button: {
                    color: 'rgba(0, 0, 0, 0.7)',
                    position: 'absolute',
                    right: 0,
                  },
                },
              },
            }}
          />
        </div>
      </TableContent>
    </TableWrapper>
  );
};