import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { isAuthorized } from '@/store/selectors';

// For routes that can only be accessed by unauthenticated users
export const OnlyAuthPages = () => {
  const isAuthenticated = useSelector(isAuthorized);

  if (isAuthenticated) {
    return <Navigate to={ROUTES.DASHBOARD.REPORTS} />;
  }
};
