import { Box, DialogContent, InputLabel, styled } from '@mui/material';


export const ModalContent = styled(DialogContent)(({theme}) => `
  padding: 0 60px;
  ${theme.breakpoints.down('md')} {
    padding: 0px 30px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`);

export const SelectLabel = styled(InputLabel)`
  margin-left: -14px;
  font-size: 16px;
`;

export const ModalTitle = styled(Box)`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

export const ActionBox = styled(Box)`
  width: 100%;
  padding-bottom: 60px;
  & :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const WarningBox = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 40px 100px;

  ${theme.breakpoints.down('md')} {
    padding: 30px 20px;
    margin: 20px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 20px;
    margin: 16px;
  }
`
);