import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import { CreateAdminUser, EditAdminUser, User } from '@/types';

import { showSpinner } from '../actions';
import { EAdmins } from '../constants';

export const getAdmins = createAsyncThunk(
  EAdmins.GET_LIST,
  async (_, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.get<User[]>('/admin/admin-users');

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const addAdmin = createAsyncThunk(
  EAdmins.ADD_ADMIN,
  async (user: CreateAdminUser, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.post<User>('/admin/admin-users', user);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const editAdmin = createAsyncThunk(
  EAdmins.EDIT_ADMIN,
  async (editData: EditAdminUser, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.patch<User>(
        `/admin/admin-users/${editData.id}`,
        editData.data
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const sendInvite = createAsyncThunk(
  EAdmins.SEND_INVITE,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.post(`/admin/invitation/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  EAdmins.DELETE_ADMIN,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(showSpinner(true));

    try {
      const response = await client.delete(`/admin/admin-users/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(showSpinner(false));
    }
  }
);
