import { Box, Drawer, ListItemText, styled } from '@mui/material';

import { InnerLink } from '@/components/common';


export const DrawerCustom = styled(Drawer)(({ theme }) => `
  flex-shrink: 0;

  & .MuiDrawer-paper {
    border-top: 1px solid ${theme.palette.border?.main};
    box-sizing: border-box;
    height: calc(100vh - 64px);
    margin-top: 64px;

    ${theme.breakpoints.down('md')} {
      height: calc(100vh - 60px);
      margin-top: 60px;
    }
  }
`
);

export const SideMenuHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const MenuItemLink = styled(InnerLink)`
  height: 48px;
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  color: ${({ theme }) => theme.palette.border?.light};
`;

export const MenuItemText = styled(ListItemText)`
  margin-left: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MenuBadge = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.neutral?.main};
  background: ${({ theme }) => theme.palette.error.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
`;