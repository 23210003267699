import { MenuItem, styled, Tabs } from '@mui/material';

export const TabsHeader = styled(Tabs)`
  margin-left: -16px;
  margin-bottom: -16px;
  margin-top: 16px;
`;

export const SuMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
