import { createSlice } from '@reduxjs/toolkit';

import { User } from '@/types';
import { AdminSlice } from '@/types/slices/admins';

import { addAdmin, deleteAdmin, editAdmin, getAdmins } from '../thunks';

const initialState: AdminSlice = {
  admins: null,
  editedAdminId: null,
};

export const adminsSlice = createSlice({
  name: 'adminsSlice',
  initialState,
  reducers: {
    clearAdmins: (state) => {
      state.admins = null;
    },
    setEditedUserId: (state, { payload }) => {
      state.editedAdminId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmins.fulfilled, (state, { payload }) => {
        state.admins = payload as User[];
      })
      .addCase(addAdmin.fulfilled, (state, { payload }) => {
        if (payload) {
          state.admins = state.admins?.length
            ? [payload, ...state.admins]
            : [payload];
        }
      })
      .addCase(deleteAdmin.fulfilled, (state, { meta }) => {
        if (state.admins?.length) {
          state.admins = [
            ...state.admins.filter(({ id }) => id.toString() !== meta.arg),
          ];
        }
      })
      .addCase(editAdmin.fulfilled, (state, { payload }) => {
        if (payload && state.admins?.length) {
          state.admins = [
            ...state.admins.map((item) =>
              item.id === payload.id ? payload : item
            ),
          ];
        }
      });
  },
});
