import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import { ClientUser, PaginatedUsers, UserParams } from '@/types';

import { setUserLoader } from '../actions';
import { EUser } from '../constants';

export const getUsers = createAsyncThunk(
  EUser.GET_LIST,
  async (userParams: UserParams, thunkApi) => {
    thunkApi.dispatch(setUserLoader(true));
    try {
      const response = await client.get<PaginatedUsers>('/admin/users', {
        params: userParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setUserLoader(false));
    }
  }
);

export const getFilteredUsers = createAsyncThunk(
  EUser.GET_FILTERED,
  async (userParams: UserParams, thunkApi) => {
    thunkApi.dispatch(setUserLoader(true));
    try {
      const response = await client.get<PaginatedUsers>('/admin/users', {
        params: userParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setUserLoader(false));
    }
  }
);

export const getMoreUsers = createAsyncThunk(
  EUser.GET_MORE,
  async (userParams: UserParams, thunkApi) => {
    thunkApi.dispatch(setUserLoader(true));
    try {
      const response = await client.get<PaginatedUsers>('/admin/users', {
        params: userParams,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setUserLoader(false));
    }
  }
);

export const getUserById = createAsyncThunk(
  EUser.GET_BY_ID,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(setUserLoader(true));
    try {
      const response = await client.get<ClientUser>(`/admin/users/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setUserLoader(false));
    }
  }
);

export const deleteUser = createAsyncThunk(
  EUser.DELETE,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(setUserLoader(true));
    try {
      const response = await client.delete(`/admin/users/${id}`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      thunkApi.dispatch(setUserLoader(false));
    }
  }
);

export const banUserInApp = createAsyncThunk(
  EUser.BAN_FROM_APP,
  async (id: string, thunkApi) => {
    try {
      const response = await client.post(`/admin/users/${id}/block`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);

export const unbanUserInApp = createAsyncThunk(
  EUser.UNBAN,
  async (id: string, thunkApi) => {
    try {
      const response = await client.delete(`/admin/users/${id}/block`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return thunkApi.rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    }
  }
);
