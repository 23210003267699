export const FIELD_IS_REQUIRED = 'The field is required';
export const PASSWORDS_DO_NOT_MATCH = "Passwords don't match";
export const INVALID_EMAIL = 'Incorrect email format';
export const EMAIL_ALREADY_EXISTS = 'Email already exists';
export const MINIMAL_LENGTH = (minNumber: number) =>
  `Not less than ${minNumber} characters`;
export const MAXIMAL_LENGTH = (maxNumber: number) =>
  `Not more than ${maxNumber} characters`;
export const MAX_MIN_PASSWORD = (min: number, max: number) =>
  `Password should be at least ${min} characters long; no more than ${max} characters`;
export const PASSWORD_SYMBOLS =
  'Password should contain at least one number, symbol, or whitespace character';
