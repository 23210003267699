import { toast, ToastContent, ToastOptions } from 'react-toastify';

const toasterDefaultOptions: ToastOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  pauseOnHover: true,
};

export const ToastService = {
  ...toast,

  success: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.success(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  error: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.error(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  warn: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.warn(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  info: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.info(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },
};
