import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  capitalize,
  FormControl,
  Select,
  TextField,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Modal } from '@/components/common';
import {
  ActionBox,
  ModalTitle,
  SelectLabel,
} from '@/components/common/Modal/styled';
import { StatusChip } from '@/components/styled/Chip';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import { setEditedUserId } from '@/store/actions';
import { addAdmin, editAdmin, sendInvite } from '@/store/thunks';
import { StatusColor } from '@/theme/theming/statusesTheme';
import { CreateAdminUser, EUserRole, EUserStatus, User } from '@/types';

import { adminSchema } from './constants/createEditAdmin';
import { RoleSelectorItem } from './styled/CreateAdmin';

type CreateEditAdminProps = {
  isOpen: boolean;
  onClose: () => void;
  editUser?: User | null;
};

export const CreateEditAdmins: FC<CreateEditAdminProps> = ({
  isOpen,
  onClose,
  editUser,
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    resetField,
    control,
  } = useForm<CreateAdminUser>({
    resolver: yupResolver(adminSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isOpen && editUser) {
      setValue('email', editUser.email);
      setValue('firstName', editUser.firstName);
      setValue('lastName', editUser.lastName);
      setValue('role', editUser.role);
    }
  }, [isOpen, editUser, setValue]);

  useEffect(() => {
    if (isOpen && !editUser) {
      resetField('role');
    }
  }, [isOpen, resetField, setValue, editUser]);

  const modalClose = useCallback(() => {
    if (editUser) {
      dispatch(setEditedUserId(null));
    }
    reset();
    onClose();
  }, [editUser, reset, onClose, dispatch]);

  const onSubmit: SubmitHandler<CreateAdminUser> = useCallback(
    (data) => {
      if (!editUser) {
        dispatch(addAdmin(data))
          .unwrap()
          .then(() => {
            modalClose();
          })
          .catch((err) => {
            ToastService.error(err.error.message);
          });
      } else {
        dispatch(
          editAdmin({
            data,
            id: editUser.id.toString(),
          })
        )
          .unwrap()
          .then(() => {
            modalClose();
          });
      }
    },
    [dispatch, modalClose, editUser]
  );

  const onStatusChange = useCallback(() => {
    if (editUser) {
      if (
        editUser.status === EUserStatus.PENDING ||
        editUser.status === EUserStatus.NEW
      ) {
        dispatch(sendInvite(editUser.id.toString()));
      } else {
        dispatch(
          editAdmin({
            id: editUser.id.toString(),
            data: {
              ...editUser,
              status:
                editUser.status === EUserStatus.ACTIVE
                  ? EUserStatus.SUSPEND
                  : EUserStatus.ACTIVE,
            },
          })
        );
      }
    }
  }, [dispatch, editUser]);

  const changeStatusTitle = useMemo(() => {
    if (editUser) {
      switch (editUser.status) {
        case EUserStatus.PENDING:
        case EUserStatus.NEW:
          return 'Resend Invite';
        case EUserStatus.ACTIVE:
          return 'Suspend';
        case EUserStatus.SUSPEND:
          return 'Activate';
        default:
          return '';
      }
    }
    return '';
  }, [editUser]);

  return (
    <Modal isOpen={isOpen} hasCloseIcon onClose={modalClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle>
          {editUser ? 'Edit Admin Details' : 'Add New Admin'}
          {editUser ? (
            <StatusChip
              label={editUser.status}
              sx={{ backgroundColor: StatusColor[editUser.status] }}
            />
          ) : null}
        </ModalTitle>
        <TextField
          fullWidth
          variant="standard"
          sx={{ marginBottom: '30px' }}
          label="Email"
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email?.message}
          disabled={editUser ? true : false}
        />
        <Box display="flex">
          <TextField
            fullWidth
            variant="standard"
            sx={{ marginBottom: '30px', marginRight: '10px' }}
            label="First Name"
            {...register('firstName')}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
          <TextField
            fullWidth
            variant="standard"
            sx={{ marginBottom: '30px', marginLeft: '10px' }}
            label="Last Name"
            {...register('lastName')}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Box>
        <FormControl sx={{ marginBottom: '30px' }} fullWidth>
          <SelectLabel id="role-select-label">Role</SelectLabel>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                variant="standard"
                label="Role"
                fullWidth
                renderValue={(value: string) => {
                  return capitalize(value);
                }}
                {...field}
              >
                {Object.values(EUserRole).map((role) => (
                  <RoleSelectorItem key={role} value={role}>
                    {role}
                  </RoleSelectorItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <ActionBox>
          {editUser ? (
            <>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid}
                fullWidth
              >
                Save
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => onStatusChange()}
              >
                {changeStatusTitle}
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={!isValid}
              fullWidth
            >
              Send an invitation
            </Button>
          )}
        </ActionBox>
      </Box>
    </Modal>
  );
};
