import { createTheme } from '@mui/material/styles';

import { components } from './components';
import { breakpoints, palette } from './theming';

export const theme = createTheme({
  components,
  palette,
  breakpoints,
});
