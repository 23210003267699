import { Box, Grid, Typography } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useAppSelector } from '@/hooks';
import { getUserLoaded } from '@/store/selectors';
import { theme } from '@/theme';

import LogoPicture from '../../assets/pictures/logo.png';
import { OnlyAuthPages } from '../guards/OnlyAuthPages';
import { TITLES } from './constants';
import {
  AuthCardContent,
  AuthCardHeader,
  AuthCardHeaderWrapper,
  AuthLayoutGrid,
  AuthTitle,
  ContentAreaCard,
} from './styled/AuthLayout';
import { AuthPageTitle } from './types';

export const AuthLayout = () => {
  const location = useLocation();
  const isUserLoaded = useAppSelector(getUserLoaded);

  const title = TITLES[location.pathname as AuthPageTitle];

  return (
    <AuthLayoutGrid
      container
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {isUserLoaded ? <OnlyAuthPages /> : null}
      <Grid item sm={10} md={6} lg={4}>
        <ContentAreaCard>
          {location.pathname !== ROUTES.AUTH.RESET_NOTIFICATION && (
            <AuthCardHeaderWrapper>
              <AuthCardHeader
                title={<AuthTitle>{title}</AuthTitle>}
                avatar={<img src={LogoPicture} alt="Logo" />}
                titleTypographyProps={{ variant: 'h2', textAlign: 'center' }}
              />
              {location.pathname === ROUTES.AUTH.INVITATION ? (
                <Box display="flex" justifyContent="center">
                  <Typography
                    fontSize="20px"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Create new password
                  </Typography>
                </Box>
              ) : null}
            </AuthCardHeaderWrapper>
          )}
          <AuthCardContent>
            <Outlet />
          </AuthCardContent>
        </ContentAreaCard>
      </Grid>
    </AuthLayoutGrid>
  );
};
