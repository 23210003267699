import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getMe } from '@/store/selectors';
import { changeMyName } from '@/store/thunks';
import { AdminUserName } from '@/types';

import { ChangePassword } from './ChangePassword';
import { userNameSchema } from './constants/userNameSchema';
import { BlockTitle, BlockWrapper } from './styled/components';

export const Settings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getMe);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    setValue,
    trigger,
    reset,
    getValues,
  } = useForm<AdminUserName>({
    resolver: yupResolver(userNameSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (user && setValue) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      trigger();
    }
  }, [setValue, trigger, user, user?.firstName, user?.lastName]);

  const onSubmitName: SubmitHandler<AdminUserName> = useCallback(
    (data) => {
      dispatch(changeMyName(data))
        .unwrap()
        .then((response) => {
          reset({
            firstName: response.firstName || '',
            lastName: response.lastName || '',
          });
        });
    },
    [dispatch, reset]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <BlockWrapper
        component="form"
        onSubmit={handleSubmit(onSubmitName)}
        marginRight="20px"
      >
        <BlockTitle>Name</BlockTitle>
        <TextField
          {...register('firstName')}
          fullWidth
          variant="standard"
          sx={{ marginTop: '20px' }}
          label="First Name"
          InputLabelProps={{ shrink: !!getValues('firstName')?.length }}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />
        <TextField
          {...register('lastName')}
          fullWidth
          variant="standard"
          sx={{ marginBottom: '20px', marginTop: '20px' }}
          label="Last Name"
          InputLabelProps={{ shrink: !!getValues('lastName')?.length }}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={
            !isValid || (!touchedFields.firstName && !touchedFields.lastName)
          }
          fullWidth
        >
          Change Name
        </Button>
      </BlockWrapper>
      <ChangePassword />
    </Box>
  );
};
