import { Box, styled, TextField, Typography } from '@mui/material';


export const TableWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral?.main};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    border-radius: 0;
  }
`;

export const TablePreHeader = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border?.main};
`;

export const TitlePreHeader = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 20px;
  }
`;

export const TableContent = styled(Box)`
  padding: 16px;
`;

export const SearchInput = styled(TextField)`
  max-width: 40%;
  min-width: 220px;
  width: fit-content;
`;
